import React, { useState } from "react";
import { IoHomeOutline } from "react-icons/io5";

const FAQExpanded = ({ heading, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="w-[100%] h-[100%] flex items-center gap-2">
      <div className="h-auto  flex items-center bg-gray-200 p-2 rounded-md">
        <IoHomeOutline size={40} />
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="font-bold">{heading}</h1>

        <div className="text-slate-500">
          {isExpanded
            ? content
            : `${content.slice(0, 150)}${content.length > 150 ? "..." : ""}`}
        <div className="w-full flex items-start justify-start">

        {content.length > 150 && (
            <button className="text-blue-500 mt-2" onClick={toggleExpansion}>
            {isExpanded ? "View Less" : "View Full"}
          </button>
        )}
        </div>
        </div>
      </div>
    </div>
  );
};

export default FAQExpanded;
