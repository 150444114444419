import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop, useDrag } from "react-dnd";
import {
  AiOutlineHolder,
  AiOutlineClockCircle,
  AiOutlineDollar,
} from "react-icons/ai";
import { RiDragDropLine } from "react-icons/ri";
import TrueFalseModal from "../Modal/TrueFalseModal";
import { formatCurrency } from "../../utils/FormatCurrency";

const KanabelContainer = () => {
  const [metrics, setMetrics] = useState([
    {
      id: 1,
      name: "Plumbing Services",
      address: "345 Park Ave S",
      amount: 860.0,
      time: "1m",
    },
    {
      id: 2,
      name: "Roofing Repairs",
      address: "4517 Washington Ave",
      amount: 1520.0,
      time: "30m",
    },
    {
      id: 3,
      name: "Paint & Drywall Repairs",
      address: "255 Columbus Cir",
      amount: 2480.0,
      time: "2h",
    },
  ]);

  const [columnA, setColumnA] = useState(metrics);
  const [columnATotal, setColumnATotal] = useState();
  const [columnB, setColumnB] = useState([]);
  const [columnBTotal, setColumnBTotal] = useState();

  const [columnC, setColumnC] = useState([]);
  const [columnCTotal, setColumnCTotal] = useState();

  const [showModal, setShowModal] = useState(false);
  const [functionCall, setFunctionCall] = useState(null);

  useEffect(() => {
    setColumnATotal(columnA.reduce((sum, metric) => sum + metric.amount, 0));
  }, [columnA]);
  useEffect(() => {
    setColumnBTotal(columnB.reduce((sum, metric) => sum + metric.amount, 0));
  }, [columnB]);
  useEffect(() => {
    setColumnCTotal(columnC.reduce((sum, metric) => sum + metric.amount, 0));
  }, [columnC]);
  const handleDrop = (item, targetColumn) => {
    let sourceColumn;
    let setSourceColumn;
    let setTargetColumn;
    if (item.columnId === "A") {
      sourceColumn = columnA;
      setSourceColumn = setColumnA;
    } else if (item.columnId === "B") {
      sourceColumn = columnB;
      setSourceColumn = setColumnB;
    } else if (item.columnId === "C") {
      sourceColumn = columnC;
      setSourceColumn = setColumnC;
    }

    if (targetColumn === "A") {
      setTargetColumn = setColumnA;
    } else if (targetColumn === "B") {
      setShowModal(true);

      setTargetColumn = setColumnB;
      setFunctionCall(() => () => {
        // Set functionCall as a function
        setSourceColumn((prev) => prev.filter((i) => i.id !== item.id));
        setTargetColumn((prev) => [
          ...prev,
          { ...item, columnId: targetColumn },
        ]);
      });
    } else if (targetColumn == "C") {
      setTargetColumn = setColumnC;
    }

    if (targetColumn !== "B") {
      setSourceColumn((prev) => prev.filter((i) => i.id !== item.id));
      setTargetColumn((prev) => [...prev, { ...item, columnId: targetColumn }]);
    }
  };

  return (
    <div className="flex w-full p-4">
      <DndProvider backend={HTML5Backend}>
        {showModal && (
          <TrueFalseModal
            functionCall={functionCall}
            setShowModal={setShowModal}
          />
        )}
        <div className="min-h-[300px] min-w-[300px]">
          <Column
            title="Prospection"
            amount={formatCurrency(columnATotal)}
            deals="5 deals"
            items={columnA}
            onDropItem={(item) => handleDrop(item, "A")}
            columnId="A"
            borderColor="#89FC00"
          />
        </div>
        <div className="min-h-[300px]  min-w-[300px]">
          <Column
            title="Request Received"
            amount={formatCurrency(columnBTotal)}
            deals="5 deals"
            items={columnB}
            onDropItem={(item) => handleDrop(item, "B")}
            columnId="B"
            borderColor="#FF5733"
          />
        </div>
        <div className="min-h-[300px]  min-w-[300px]">
          <Column
            title="Request Resolved"
            amount={formatCurrency(columnCTotal)}
            deals="50 deals"
            items={columnC}
            onDropItem={(item) => handleDrop(item, "C")}
            columnId="C"
            borderColor="#89FC00"
          />
        </div>
      </DndProvider>
    </div>
  );
};

const Column = ({
  title,
  amount,
  deals,
  items,
  onDropItem,
  columnId,
  borderColor,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.ITEM,
    drop: (item) => {
      onDropItem(item, columnId);
    },
    canDrop: (item) => item.columnId !== columnId,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      style={{
        flex: 1,
        margin: "8px",
        minHeight: "300px",
        backgroundColor: "white",
        border: `1px solid ${borderColor}`,
        borderRadius: "8px",
        padding: "8px",
      }}
      className="rounded-md"
    >
      <div className="border-b pb-4 mb-4">
        <h2 className="text-gray-800 font-medium text-lg">{title}</h2>
        <div className="text-gray-500 text-sm">{amount}</div>
        <div className="text-gray-400 text-xs">{deals}</div>
      </div>
      <div className="flex flex-col gap-3">
        {items?.map((item) => (
          <DragItem
            key={item.id}
            item={item}
            columnId={columnId}
            borderColor={borderColor}
          />
        ))}
      </div>
      {isOver && canDrop && (
        <div className="w-full relative top-0 inset-0 h-full flex justify-center items-center bg-gray-50">
          <div className="flex gap-2 text-gray-600 items-center text-sm">
            <RiDragDropLine size={20} /> Drop inside {title}
          </div>
        </div>
      )}
    </div>
  );
};

const DragItem = ({ item, columnId, borderColor }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.ITEM,
    item: { ...item, columnId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: "8px",
        marginBottom: "8px",
        cursor: "move",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        border: `1px solid #E0E0E0`,
      }}
      className="rounded-md text-sm text-gray-700"
    >
      <div className="flex justify-between items-center mb-2">
        <div className="font-medium text-base">{item.name}</div>
        <div className="text-gray-400 text-xs">{item.time}</div>
      </div>
      <div className="text-gray-500 text-xs mb-2">{item.address}</div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center text-xs text-gray-400">
          <AiOutlineHolder size={18} />
          <AiOutlineDollar size={18} />
          <AiOutlineClockCircle size={18} />
        </div>
        <div className="font-medium text-gray-800">{item.amount}</div>
      </div>
    </div>
  );
};

const ItemType = {
  ITEM: "ITEM",
};

export default KanabelContainer;
