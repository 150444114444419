import React from "react";
import CustomWrapper from "../../component/dividedScreen";
import { Modal } from "../addTeam";
import smallRevens from "../../assets/icons/Revens/smallRevensCalling.svg";
import Button from "../../component/button";
import { useNavigate } from "react-router-dom";

const LastStep = () => {
  const navigate =useNavigate()
  return (
    <CustomWrapper isBubble isCenter>
      <div className="w-[66%] mx-auto">
        <Modal isWhiteBackground={true}>
          <div className="w-[100%] text-center">
            <div className="font-semibold text-xl mb-4">Congratulations!</div>
            <div className="font-light text-lg mb-5">
              Congratulations Boss invitation has already sent to your dream
              team!
            </div>
          </div>
          <div className="w-[50%] flex justify-start p-4 h-[100px] mx-auto bg-RGreen rounded-md">
            <div className="w-[20%]">
              <img src={smallRevens} className="w-[60%]" />
            </div>
            <div className="w-[80%] -ml-4 flex justify-between flex-col">
              <div className="text-lg font-semibold text-white">Revens</div>
              <div className="text-sm  text-white">
                Your dashboard is also ready, let’s dive into that!
              </div>
            </div>
          </div>
          <div className="w-[100%] flex justify-center my-5">
            <Button onClick={()=>{navigate('/dashboard')}}>Go to dashboard</Button>
          </div>
        </Modal>
      </div>
    </CustomWrapper>
  );
};

export default LastStep;
