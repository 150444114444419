import React from "react";
import Skeleton from "../Skeleton";
import { useGetPropertyChainQuery } from "../../redux/slices/dashboard";
import Chart from 'react-apexcharts';
import dataNotFound from '../../assets/icons/404/2.svg'


const SingleChainProperty = () => {
    const { data: propertyChainData, isLoading } = useGetPropertyChainQuery();

    // Parse the data
    const countSingleProperty = propertyChainData?.data?.countSingleProperty || 0;
    const countChainProperty = propertyChainData?.data?.countChainProperty || 0;

    // Chart options
    const chartOptions = {
        series: [{
            data: [countSingleProperty, countChainProperty]
        }],
        chart: {
            type: 'bar',
            height: '350',
            toolbar: {
              show: false // This will remove the download button
          },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        xaxis: {
            show: false,
            categories: [], // Empty initially, will be set later
            labels: {
              style: {
                colors: "#94A3B8", // Tailwind color slate-400
                fontSize: "0px", // Adjust font size as needed
              },
            },
          },
          yaxis: {
            show: false,
            labels: {
              style: {
                colors: "#94A3B8", // Tailwind color slate-400
                fontSize: "12px", // Adjust font size as needed
              },
            
            },
          },
        dataLabels: {
            enabled: false
        },
        grid: {
            yaxis: {
              lines: {
                show: false, // Disable horizontal grid lines
              },
            },
            xaxis:{
              lines: {
                show: false, // Disable vertical grid lines
              },
            }
          },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Single Property', 'Chain Property'],
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }
    };

    return (
        <>
            {!isLoading ? (
                <div className="w-[100%] rounded-lg p-4 bg-white h-[100%] flex flex-col justify-between pb-[10px]">
                    <div className="w-full flex justify-between">
                        <h1 className="font-semibold">Single vs Chain Property Clients</h1>
                    </div>
               {propertyChainData ?     
               <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        height={'70%'}
                    />:<div className="w-full flex flex-col bg-[#7c8ba130]  items-center justify-start  h-[90%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
                    <img src={dataNotFound} className="h-60 w-60" alt="" />
                    <div className="w-full flex justify-center">
              
                    <p className="text-xl font-medium">No recent Activities</p>
                    </div>
                     </div>}
                </div>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

export default SingleChainProperty;
