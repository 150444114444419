import React, { useEffect, useState } from "react";
import ProgressBar from "../ProgressBar/progressBar";
import { HiCubeTransparent } from "react-icons/hi";
import { MdArrowDropDown } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";
import { useGetScenarioFrequencyQuery } from "../../redux/slices/onBoarding";

const SelectableTabs = ({
  setCategoryId,
  setSubCategoryId,
  categoryData,
  subCategoryId,
  categoryId,
}) => {
  // State to track which item's subcategories are currently shown
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const progress = (1 / 2) * 100;

  const toggleSubCategories = (index) => {
    setOpenItemIndex(index === openItemIndex ? null : index);
  };
  const findCategoryId = (subCategoryId) => {
    
    const category = categoryData?.data?.find((items) =>
      items.categories.find((category) =>
        category?.subCategories.find(
          (sub) => sub.subCategoryId === subCategoryId
        )
      )
    );

    if (category) {
      const foundCategory = category?.categories?.find((cat) =>
        cat?.subCategories?.find((sub) => sub?.subCategoryId === subCategoryId)
      );

      if (foundCategory) {
        setCategoryId(foundCategory.categoryId);
        setOpenItemIndex(foundCategory.categoryName);
      }
    }
  };

  // Call findCategoryId when subCategoryId changes
  useEffect(() => {
    findCategoryId(subCategoryId);
  }, [subCategoryId]);

  return (
    <div className="w-[100%] h-[100%] rounded-md flex items-center  flex-col ">
      <div className="flex flex-col p-4 gap-3">
        <div className="font-semibold  text-xl">Client Performance Metrics</div>
        <h1 className="text-sm text-gray-700">
          R-World offers a wide range of KPI’s to keep you informed and in
          control of your clients. Select the areas within your agency that
          you'd like to prioritize for data insights:
        </h1>
      </div>
      {/* <div className="flex items-center p-2  justify-center gap-2 w-[100%]">
        <h1>1/2</h1>
        <ProgressBar progress={progress} />
      </div> */}
      <div className="overflow-y-auto w-full h-full ">
        {categoryData?.data?.map((items, index) =>
          items?.categories?.map((categories, indexx) => (
            <div className="border flex flex-col w-[100%] " key={index}>
              <div
                onClick={() => toggleSubCategories(categories?.categoryName)}
                className="cursor-pointer flex p-4 w-[100%] items-center justify-between gap-4"
              >
                <div className="flex w-[100%]  items-center gap-4">
                  <HiCubeTransparent size={35} />
                  <p className="w-full">{categories?.categoryName}</p>
                  <p className="w-full flex justify-end">
                    {openItemIndex === indexx ? (
                      <MdArrowDropUp />
                    ) : (
                      <MdArrowDropDown />
                    )}
                  </p>
                </div>
              </div>

              {openItemIndex === categories?.categoryName &&
                categories?.subCategories?.map((subCategory, indexxx) => (
                  <div
                    onClick={() => {
                      setSubCategoryId(subCategory?.subCategoryId);
                      setCategoryId(categories?.categoryId);
                    }}
                    className={`p-4 hover:bg-gray-300 bg-gray-50 flex items-center justify-between cursor-pointer ${subCategory?.subCategoryId === subCategoryId && 'border-blue-400 border'} `}
                  >
                    <p> {subCategory?.subCategoryName}</p>

                    <div>
                      {" "}
                      <label
                        className={`relative flex items-center rounded-full cursor-pointer`}
                        htmlFor={`customStyle-${subCategory?.subcategoryName}`}
                      >
                        <input
                          type="checkbox"
                          className={` before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:bg-blue-600 checked:before:bg-RGreen hover:scale-105 hover:before:opacity-0`}
                          id={`customStyle-${subCategory?.subcategoryName}`}
                          checked={
                            subCategory?.completed 
                            
                          }
                        />
                        <span className="absolute text-RGreen transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="white"
                            stroke="RGreen"
                            strokeWidth="1"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SelectableTabs;
