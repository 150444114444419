// AnotherComponent.js
import React, { useState } from "react";
import { countryData } from "../../utils/countryData";
import ReactCountryFlag from "react-country-flag";
import { MdArrowDropDown } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";

const CountryCodeDropDown = ({setSelectedCode, selectedCode}) => {
  const [data,setData]=useState(countryData)
  const [filteredData,setFilteredData]=useState(countryData)
 
  const [isCollapsed, setIsCollapsed] = useState(true);
  
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleChange=(e)=>{
    const filtered = data.filter((country) =>
      country.country.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(filtered)
  }

  return (
    <div className="w-[100%] h-[100%]   overflow-hidden">
      <h1
        onClick={toggleCollapse}
        className=" cursor-pointer items-center border-r w-full p-2 gap-2 flex justify-evenly justify-center text-sm  text-center font-semibold"
      >
        <ReactCountryFlag
          countryCode={selectedCode?.iso}
          svg
          style={{
            width: "1.5em",
            height: "1.5em",
          }}
          title={selectedCode?.iso}
        />
        {isCollapsed ?<MdArrowDropDown />:<MdArrowDropUp/>}

      </h1>
      {!isCollapsed && (
        <ul className="absolute max-h-96 z-10 overflow-y-auto ">
          <input type="text" className="mt-2 rounded border w-[100%]  p-1 sticky top-1" placeholder="Search for a country" onChange={handleChange} />
          {filteredData?.map((country, index) => (
            <li
              key={index}
              onClick={() => {
                setSelectedCode(country);
                setIsCollapsed(true);
              }}
              className="flex items-center gap-2 z-10 p-4 border-b bg-white hover:bg-gray-50"
            >
              <div className="w-[1.5em] h-[1.5em]">
                <ReactCountryFlag
                  countryCode={country?.iso}
                  svg
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                  }}
                  title="US"
                />
              </div>
              <span className="flex-grow">{country.country}</span>
              <span className="font-bold text-gray-600">({country.code})</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CountryCodeDropDown;
