import React, { useState } from "react";
import AdvanceTable from "./TeamTable";
import { FaUserAlt } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { RiLoader4Line } from "react-icons/ri";
import Modal from "../../../component/Modal";
import AddNewSalesEmployee from "./AddEmployee";
import Tablewrap from "../../../component/Table/TableWrap";
import UniversalTable from "../../../component/Table/UniversalTable";

const TeamDetails = () => {
  const [showAddUser, setShowAddUser] = useState();
  return (
    <>
    
      {showAddUser ? (
        <div>
          <AddNewSalesEmployee setShowAddUser={setShowAddUser} />{" "}
        </div>
      ) : (
        <div className="w-full p-6  h-full">
          <div className="flex gap-2 mb-6 box-border">
            <div className="w-[33%] gap-3 flex bg-slate-300 bg-opacity-30 h-36 p-8 box-border rounded-lg">
              <div className="h-full bg-green-400 bg-opacity-25 p-4 w-20 rounded-lg box-border flex justify-center items-center">
                <FaUserAlt size={30} />
              </div>
              <div className="flex flex-col items-start  ">
                <h1 className="justify-self-start font-medium text-slate-400 ">
                  Total Employees
                </h1>
                <h1 className="text-5xl">35</h1>
              </div>
            </div>
            <div className="w-[33%] gap-3 flex bg-slate-300 bg-opacity-30 h-36 p-8 box-border rounded-lg">
              <div className="h-full bg-green-400 bg-opacity-25 p-4 w-20 rounded-lg box-border flex justify-center items-center">
                <FaUserCheck size={30} />
              </div>
              <div className="flex flex-col items-start  ">
                <h1 className="justify-self-start font-medium text-slate-400 ">
                  Active Employees
                </h1>
                <h1 className="text-5xl">35</h1>
              </div>
            </div>

            <div className="w-[33%] gap-3 flex bg-slate-300 bg-opacity-30 h-36 p-8 box-border rounded-lg">
              <div className="flex flex-col items-start  ">
                <h1 className="justify-self-start font-medium text-slate-400 ">
                  {" "}
                  Yearly Goals
                </h1>
                <h1 className="text-5xl">85%</h1>
              </div>
              <div className="h-full  bg-opacity-25 p-4 w-20 rounded-lg box-border flex justify-center items-center">
                <RiLoader4Line size={50} color="green" />
                {showAddUser && <Modal setShowAddUser={setShowAddUser} />}
              </div>
            </div>
          </div>

          {/* <AdvanceTable setShowAddUser={setShowAddUser} /> */}
          <div className="h-[600px]">
            <Tablewrap label={"Hotel Onboarded"}>
              <UniversalTable />
            </Tablewrap>
          </div>
          <div className="h-[600px]">
            <Tablewrap label={"Hotel Onboarded"}>
              <UniversalTable />
            </Tablewrap>
          </div>
          <div className="h-[600px]">
            <Tablewrap label={"Hotel Onboarded"}>
              <UniversalTable />
            </Tablewrap>
          </div>

          <Tablewrap label={"Hotel Onboarded"}>
            <UniversalTable />
          </Tablewrap>
        </div>
      )}
    </>
  );
};

export default TeamDetails;
