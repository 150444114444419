import React, { useEffect, useState } from "react";
import Skeleton from "../Skeleton";
import Chart from "react-apexcharts";
import { useGetChannelPerformanceQuery } from "../../redux/slices/dashboard";
import NormalDropDown from "../DropDown/NormalDropDown";
import dataNotFound from "../../assets/icons/404/3.svg";
import { Tooltip } from "react-tooltip";
import { formatCurrency } from "../../utils/FormatCurrency";

const ChannelPerformance = () => {
  const [selectedValue, setSelectedValue] = useState('Revenue');
  const {
    data: channelPerformanceData,
    isLoading,
    isFetching,
  } = useGetChannelPerformanceQuery({ 
    type: selectedValue });
  const [chartData, setChartData] = useState({ series: [], labels: [] });
  const options = ["Revenue", "ADR", "Room Nights"];

  console.log(channelPerformanceData, "channel");

  function convertArrayToObjectPairs(data) {
    // 1. Sort the data by value in descending order
    const sortedData = [...data].sort((a, b) => b.value - a.value);

    // 2. Create an empty array to store the results
    const results = [];

    // 3. Loop through the sorted data
    for (let i = 0; i < sortedData.length; i++) {
      // 4. Add the first three objects directly
      if (i < 3) {
        results.push(sortedData[i]);
      } else {
        // 5. For the fourth object, create an "others" object
        if (i === 3) {
          results.push({ source: "Others", value: 0 });
        }
        // 6. Add the current object's value to the "others" object
        results[3].value += sortedData[i].value;
      }
    }

    return results;
  }

  useEffect(() => {
    if (channelPerformanceData?.data) {
      let convertedObj = convertArrayToObjectPairs(channelPerformanceData.data);
      const series = convertedObj.map((item) => item.value);
      const labels = convertedObj.map((item) => item.source);
      setChartData({ series, labels });
    }
  }, [channelPerformanceData?.data]);

  const chartOptions = {
    series: chartData?.series || [], // Default to empty array if chartData.series is undefined
    chart: {
      type: "donut",
      width: "100%",
    },
    labels: chartData?.labels || [], // Default to empty array if chartData.labels is undefined
    colors: [
      "#FF4560",
      "#775DD0",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#008FFB",
      "#00E396",
    ],
    legend: {
      show: false, // Hide the default legend
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "14px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#373d3f",
              offsetY: -10,
              width: "100%",
            },
            value: {
              show: true,
              fontSize: "20px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: "#373d3f",
              offsetY: 10,
              formatter: function (val) {
                return `${selectedValue !== "Room Nights" ?formatCurrency(val):val}`;
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "14px",
              fontWeight: 400,
              color: "#373d3f",
              formatter: function (w) {
                return ` ${selectedValue !== "Room Nights" ? formatCurrency( w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)) : w.globals.seriesTotals.reduce((a, b) => a + b, 0).toFixed(2)}`;
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <>
      {!isLoading || !isFetching ? (
        <div className="w-full rounded-lg p-4 bg-white h-full flex flex-col items-center">
          <div className="w-full flex items-center gap-2 justify-between ">
            <div className="flex items-center gap-2">
              <h1 className="text-sm font-semibold">Channel Performance</h1>
              {/* <p className="text-xs text-slate-400">Last 7 days</p> */}
            </div>
            <div className={` w-[35%] `}>
              <NormalDropDown
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                options={options}
                label={"Select value"}
              />
            </div>
          </div>
          <div className="w-full flex h-full items-center justify-center">
            {channelPerformanceData ? (
              <>
                <Chart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="donut"
                  width="100%" // Adjusted width to fit within container
                  height="280"
                />

                <div
                  className="legend-container my-auto"
                  style={{ minHeight: "50px", overflowY: "auto" }}
                >
                  {chartOptions.labels.map((label, index) => (
                    <div
                      key={index}
                      className="legend-item flex text-xs flex-col justify-center items-start "
                      style={{ display: "", padding: "5px 0" }}
                    >
                      <div className="flex items-center">
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor:
                              chartOptions.colors[
                                index % chartOptions.colors.length
                              ],
                            marginRight: "10px",
                          }}
                        ></div>
                        <div
                          className="w-24 truncate"
                          style={{ fontSize: "14px" }}
                          data-tooltip-id={label}
                          data-tooltip-content={label}
                        >
                          {label}:
                          <Tooltip
                            id={label}
                            style={{
                              borderRadius: "10px",
                              maxWidth: "350px",
                              fontSize: "12px",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                              overflow: "hidden",
                              zIndex: 20,
                            }}
                          />{" "}
                        </div>
                      </div>
                      <p className="">{selectedValue=='Room Nights' ?chartOptions.series[index] :formatCurrency(chartOptions.series[index])}</p>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="w-full flex flex-col bg-[#7c8ba130]  items-center justify-start  h-[99%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
                <img src={dataNotFound} className="h-60 w-60" alt="" />
                <div className="w-full flex justify-center">
                  <p className="text-xl font-medium">No recent Activities</p>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default ChannelPerformance;
