import React from "react";
import Skeleton from "../Skeleton";
import dataNotFound from "../../assets/icons/404/1.svg";
import { useNavigate } from "react-router-dom";
import getToken from "../../utils/getToken";
import { Tooltip } from "react-tooltip";
import { useSwitchToRatexQuery } from "../../redux/slices/onBoarding";
import { formatCurrency } from "../../utils/FormatCurrency";
const UniversalTable = ({ dataArray, isLoading, showActions = false }) => {
  const { data: switchToRate } = useSwitchToRatexQuery();
  const navigate = useNavigate();
  const keys = dataArray && dataArray[0] && Object.keys(dataArray[0]);
  if (isLoading) {
    return (
      <div className="h-[200px]">
        <Skeleton />
      </div>
    );
  }
  if (!dataArray) {
    return (
      <div className="w-full m-4 flex bg-[#7c8ba130]  items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
        <img src={dataNotFound} className="h-60 w-60" alt="" />
        <div className="w-full flex justify-center">
          <p className="text-xl font-medium">No recent Activities</p>
        </div>
      </div>
    );
  }
  const token = getToken();

  // const handleNavigate = (hId) => {
  //   const token = switchToRate?.data?.data?.token;
  //   if (token) {
  //     window.location.href = `http://localhost:3000/ratex/token:${token}/hId:${hId}`;
  //   } else {
  //     console.error("Token not received from switchToRate");
  //   }
  // };

  const handleNavigate = (hId) => {
    const token = switchToRate?.data?.data?.token;
    if (token) {
      window.open(
        `https://ratex.retvenslabs.com/ratex/token:${token}/hId:${hId}`,
        "_blank"
      );
    } else {
      console.error("Token not received from switchToRate");
    }
  };

  return (
<div className="bg-white w-[100%] h-[100%] rounded-lg overflow-hidden">
      <div className="h-full overflow-y-auto"> {/* Ensure this div allows scrolling */}
        <div className="w-full sticky top-0 bg-white z-10"> {/* Make header sticky */}
          <div className="h-20 flex justify-between items-center w-full text-sm leading-none text-gray-600">
            {keys?.includes("name") && (
              <th className="font-normal flex justify-center min-w-32">Name</th>
            )}
            {keys?.includes("designation") && (
              <th className="font-normal flex justify-center min-w-32">Designation</th>
            )}
            {keys?.includes("hotelAssigned") && (
              <th className="font-normal flex justify-center min-w-32">Hotel Assigned</th>
            )}
            {keys?.includes("propertyName") && (
              <th className="font-normal flex justify-center min-w-32">Name</th>
            )}
            {keys?.includes("revenue") && (
              <th className="font-normal flex justify-center min-w-32">Revenue Growth</th>
            )}
            {keys?.includes("occupancy") && (
              <th className="font-normal flex justify-center min-w-32">Occupancy</th>
            )}
            {keys?.includes("ADR") && (
              <th className="font-normal flex justify-center min-w-32">ADR</th>
            )}
            {keys?.includes("status") && (
              <th className="font-normal flex justify-center min-w-32">Action</th>
            )}
            {showActions && (
              <th className="font-normal text-center min-w-32">Action</th>
            )}
          </div>
        </div>
        <div className="w-[100%] flex flex-col justify-between">
          {dataArray &&
            dataArray?.map((item, index) => (
              <div
                key={index}
                className="h-20 text-sm flex justify-between items-center w-full leading-none text-gray-700 border-b border-t border-gray-200 bg-white hover:bg-gray-100"
              >
                {item?.name && (
                  <div className="w-32 flex items-center justify-center truncate">
                    <button className="font-semibold ">{item?.name}</button>
                  </div>
                )}
                {item?.designation && (
                  <div className="w-32 flex  items-center justify-center">
                    <button className="font-semibold ">
                      {item?.designation}
                    </button>
                  </div>
                )}
                {(item?.hotelAssigned == 0 || item?.hotelAssigned) && (
                  <div className="w-32 flex items-center justify-center">
                    <div className="font-semibold">{item?.hotelAssigned}</div>
                  </div>
                )}
                {item?.propertyName && (
                  <div className="w-32 flex items-center truncate  justify-start">
                    <div className="flex flex-col items-center w-full">
                      <p
                        className="font-bold text-center truncate w-full max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis"
                        data-tooltip-id={item?.propertyName}
                        data-tooltip-content={item?.propertyName}
                      >
                        {item?.propertyName}
                      </p>
                      <p className="text-xs">{item?.propertyType}</p>
                    </div>
                    <Tooltip
                      id={item?.propertyName}
                      style={{
                        borderRadius: "10px",
                        maxWidth: "350px",
                        fontSize: "12px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflow: "hidden",
                        zIndex: 20,
                      }}
                    />
                  </div>
                )}
                {item?.revenue && (
                  <div className="w-32 flex justify-center">
                    <ValueDisplay
                      amount={formatCurrency(item?.revenue)}
                      percentage={item?.revenueChangePercentage}
                    />
                  </div>
                )}
                {(item?.occupancy === 0 || item?.occupancy) && ( // Fixed the syntax error
                  <div className="w-32 flex items-center justify-center">
                  <ValueDisplay
                    amount={item?.occupancy}
                    percentage={item?.occupancyChangePercentage}
                    isOccupency={true}
                  />
                  </div>
                )}
                {item?.ADR && (
                  <div className="w-32 flex items-center justify-center ">
                    {item?.ADR}
                  </div>
                )}
                {item?.status && (
                  <div className="w-32  flex items-center justify-center">
                    <button className="text-[#77A980] hover:underline">
                      {item?.status}
                    </button>
                  </div>
                )}
                {showActions === "client" && (
                  <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                    <div
                      // onClick={()=>{navigate(`/client/${item?.hotelCode}`)}}
                      onClick={() => handleNavigate(item?.hId)}
                      className="text-[#77A980] hover:underline"
                    >
                      Visit
                    </div>
                    <div
                      onClick={() => {
                        navigate(`/client/${item?.hId}`);
                      }}
                      className="text-[#a98577] mt-2 hover:underline"
                    >
                      View
                    </div>
                  </div>
                )}
                {showActions === "user" && (
                  <div className="w-32 flex flex-col items-center justify-center cursor-pointer ">
                    <div
                      onClick={() => {
                        navigate(`/user/${item?.userId}`);
                      }}
                      className="text-[#77A980] hover:underline"
                    >
                      Visit
                    </div>
                    <div className="text-[#a98577] hover:underline">
                      Deactivate
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
const ValueDisplay = ({ amount, percentage, isOccupency }) => {
  const isPositive = percentage > 0;
  return (
    <div className="flex items-center justify-center text-xs w-full gap-2">
      <p className={``}>{isOccupency ? `${amount}%` : amount}</p>
      <p className={isPositive ? "text-green-500" : "text-red-500"}>
        {isPositive ? `↑ ${percentage}%` : `↓ ${Math.abs(percentage)}%`}
      </p>
    </div>
  );
};

export default UniversalTable;
