import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Skeleton from "../Skeleton";
import { useGetStarCategoryQuery } from "../../redux/slices/dashboard";
import Chart from 'react-apexcharts';
import dataNotFound from '../../assets/icons/404/4.svg'

const ClientByStarCategory = () => {
    const { data: starCategoryData, isLoading } = useGetStarCategoryQuery();

    // Parse the data
    const totalHotel = starCategoryData?.data?.totalHotel || 0;
    const starCategory = starCategoryData?.data?.starCateroy || [];
    const series = starCategory.map(category => category.count);
    const labels = starCategory.map(category => `${category.hotelStarRating} Star`);

    // Chart options
    const chartOptions = {
        series: series,
        chart: {
            type: 'donut',
            width: '100%',
            height: '500px' // Adjust this value as needed
        },
        labels: labels,
        colors: ['#FF4560', '#775DD0', '#00E396', '#FEB019', '#FF4560', '#008FFB', '#00E396'],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            formatter: function (val, opts) {
                return `<div style="width: 100%; text-align: left;">${val}: ${opts.w.globals.series[opts.seriesIndex]}</div>`;
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'Hotels',
                            formatter: function (w) {
                                return totalHotel;
                            }
                        },
                        // {{ edit_1 }} Adjust label font size and position
                        style: {
                            fontSize: '14px', // Adjust font size as needed
                            colors: ['#000'] // Change color if necessary
                        }
                    }
                }
            }
        }
    };

    return (
        <>
            {!isLoading ? (
                <div className="w-full h-full rounded-lg p-4 bg-white flex flex-col  items-center">
                    <div className="w-full flex justify-between mb-4">
                        <h1 className="font-semibold">Client by Star Category</h1>
                        {/* <div className="flex items-center justify-center gap-3">
                            <div>
                                <IoIosInformationCircleOutline />
                            </div>
                        </div> */}
                    </div>
                    <div className="w-full flex h-full items-center justify-center">
                     { starCategoryData?.data ? <Chart
                            options={chartOptions}
                            series={chartOptions.series}
                            type="donut"
                            width={400}
                        />:  <div className="w-full flex bg-[#7c8ba130] flex-col  items-center justify-start  h-[90%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
                        <img src={dataNotFound} className="h-60 w-60" alt="" />
                        <div className="w-full flex justify-center">
                  
                        <p className="text-xl font-medium">No recent Activities</p>
                        </div>
                         </div>}
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

export default ClientByStarCategory;
