import React from "react";
import CustomCheckBox from "../CheckBox/customCheckBox";

const CompanyTeams = ({
  finalData,
  setFinalData,
  teamError,
  setTeamError,
  loginInfo,
}) => {
  const onChangeCheckbox = (label, e) => {
    setFinalData((prevState) => {
      const department = Array.isArray(prevState?.department) ? prevState.department : [];
      const isPresent = department.some(dept => dept?.businessDepartment === label);
      
      return {
        ...prevState,
        department: isPresent
          ? department.filter(dept => dept?.businessDepartment !== label) // Remove if present
          : [...department, { businessDepartment: label }], // Add if not present
      };
    });
    setTeamError("");
  };

  const isChecked = (label) => {
    return loginInfo?.data?.companyDetail?.department?.some(
      (dept) => dept?.businessDepartment === label
    );
  };

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">R-World for Every Team</h1>
        <p className="text-[#96A0AD] my-2">
          R-World software is designed to empower all aspects of your
          multi-hotel agency{" "}
        </p>
      </div>
      <div>
        <p className="my-2">
          Please Select the department within your agency that will be using
          R-World software
        </p>
        <CustomCheckBox
          label="Revenue Management"
          finalData={finalData}
          onChangeCheckbox={onChangeCheckbox}
          checked={isChecked("Revenue Management")}
        />
        <CustomCheckBox
          label="Sales"
          finalData={finalData}
          onChangeCheckbox={onChangeCheckbox}
          checked={isChecked("Sales")}
        />
        <CustomCheckBox
          label="Marketing"
          finalData={finalData}
          onChangeCheckbox={onChangeCheckbox}
          checked={isChecked("Marketing")}
        />
        <CustomCheckBox
          label="Finance & Accounting"
          finalData={finalData}
          onChangeCheckbox={onChangeCheckbox}
          checked={isChecked("Finance & Accounting")}
        />
      </div>
      {teamError && <p className="text-red-500">{teamError}</p>}
    </div>
  );
};

export default CompanyTeams;
