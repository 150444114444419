import React, { useEffect } from 'react'
import './backdrop.css'

const BackDrop = ({ children, isActive=true }) => {
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isActive]);

  return (
    isActive ? <div className='backdrop'>{children}</div> : null
  );
}

export default BackDrop
