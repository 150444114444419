import React, { useEffect } from "react";
import RWorldBlack from "../../assets/icons/RWorldBlack.png";
import Button from "../button";
import ReusableInput from "../InputField/ReusableInput";

import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useSignupMutation,
} from "../../redux/slices/onBoarding";

import GoogleSignInButton from "../googleButton";
import Cookies from "js-cookie";

import { toast } from "react-toastify";
import { decryptText } from "../../utils/EncryptionAlgo";
import { encryptionKey } from "../../helpers/helpers";
const Login = ({ setRecentLogin ,setLoading ,recentLoginEmail}) => {
  const methods = useForm();
  const [login] = useLoginMutation();
  const navigate = useNavigate();
  const [signup] = useSignupMutation();
  const onSubmit = (data) => {
    setLoading(true)
    login(data)
      .unwrap()
      .then(async(res) => {
        
        Cookies.set("token", res?.data?.token, { expires: 7 });
        const prevLogin = Cookies.get("recentLogin")
          ? JSON.parse(Cookies.get("recentLogin"))
          : [];
       
          const isEmailInPrevLogin = prevLogin?.some(item => item.email === res?.data?.email);
        if (!isEmailInPrevLogin) {
          Cookies.set(
            "recentLogin",
            JSON.stringify([
              ...prevLogin,
              { email: res?.data?.email, name: res?.data?.fullName },
            ]),
            { expires: 7 }
          );
          setRecentLogin([...prevLogin, { email: res?.data?.email,name: res?.data?.fullName }]);
        }
       console.log(encryptionKey,"encryption key")
        const status= await decryptText(res?.data?.status,encryptionKey)

        if(status==='onboarding') {
          navigate("/onboarding");
        }else{
          console.log("first")
          navigate("/dashboard");
        }
        console.log(status,"status")
        setLoading(false)
        // navigate("/onBoarding");
      })
      .catch((err) => {
        setLoading(false)

        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
  const handleSuccess = (response) => {
    console.log("Login Success:", response);
    Cookies.set("clientToken", response.stsTokenManager.accessToken, {
      expires: 7,
    });
    signup({ accountType: "google", email: response.email })
      .unwrap()
      .then((res) => {
        navigate("/onBoarding");
        Cookies.set("token", res?.data?.token, { expires: 7 });
        const prevLogin = JSON.parse(Cookies.get("recentLogin"));
        Cookies.set(
          "recentLogin",
          JSON.stringify([...prevLogin, { email: res?.data?.email }]),
          { expires: 7 }
        );
        setRecentLogin([...prevLogin, { email: res?.data?.email }]);
        console.log(prevLogin, "prev Login");
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
      });
  };
 useEffect(() => {
  methods.setValue('email',recentLoginEmail)
 },[recentLoginEmail])
  const handleFailure = (response) => {
    console.log("Login Failed:", response);
    // Handle login failure here
  };

  return (
    <FormProvider  {...methods}>
      <form className="w-[100%] h-[100%]" onSubmit={methods.handleSubmit(onSubmit)}>
        {/* <div
          className="flex bg-custom-image bg-no-repeat  bg-right-bottom   flex-col bg-slate-100 h-screen w-full justify-center"
          style={{ backgroundPosition: "right 0 bottom -60px" }}
        > */}
        <div className="h-full w-full px-4">
          <div className="flex flex-col items-center justify-center">
            <div className="bg-white shadow rounded-lg  w-full p-10 mt-16">
              <div className="flex justify-between items-start gap-3 ">
                <div className=" flex flex-col  text-gray-800">
                  <div className="flex items-center gap-2  ">
                    <p className="text-base md:text-xl  font-semibold">
                      {" "}
                      Welcome to
                    </p>
                    <span className="flex w-16 h-10 items-center">
                      <img
                        className="object-cover"
                        src={RWorldBlack}
                        alt="Logo"
                      />
                    </span>
                  </div>
                  <div className=" text-base md:text-4xl font-medium ">
                    Log in
                  </div>
                </div>
                <div className="flex flex-col items-start pt-2">
                  <h2 className="text-sm">No Account?</h2>
                  <Link
                    className="no-underline text-gray-800 outline-none"
                    to={"/signup"}
                  >
                    <div className="text-RGreen"> Sign up</div>
                  </Link>
                </div>
              </div>
              {/* <div className="my-10 w-64">
                <Button type="primary">
                    <img src={RownLogo} height={19} width={19} alt="" />
                    <p className="text-base font-medium ml-4 ">
                      Sign In with R-OWN
                    </p>
                  </Button>
                <GoogleSignInButton
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                />
              </div> */}
              {/* <div className="w-full flex items-center justify-between py-5">
                  <hr className="w-full bg-white" />
                  <p className="text-base font-medium leading-4 px-2.5 text-gray-400">
                    OR
                  </p>
                  <hr className="w-full bg-white" />
                </div> */}
              <div className="mt-8 flex flex-col items-between">
                <ReusableInput
                  label="Enter your Email Address"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                />
                <ReusableInput
                  label="Enter Your Password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  rules={{ required: "Password is required" }}
                  showIcon={true}
                />

                <div className="w-[100%] flex justify-end">
                  <Link to={"/forgot-password"}>
                    <p className="text-blue-400 flex-end">Forgot Password</p>
                  </Link>
                </div>
              </div>

              <div className="mt-8">
                <Button>Sign In</Button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </form>
    </FormProvider>
  );
};

export default Login;
