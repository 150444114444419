import React, { useRef, useState } from "react";
import FAQBox from "../../component/FAQ/FAQBox";
import { LuRefreshCw } from "react-icons/lu";
import { RiAttachment2 } from "react-icons/ri";
import { FaRegImage } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { IoIosEye } from "react-icons/io";
import { MdFilePresent } from "react-icons/md";
import { useGetAllFaqQuery, usePostHelpandSupportMutation } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useIsIpad } from "../../utils/isIpad";

const Support = ({setSelectedItem}) => {
  const [text, setText] = useState("");
  const navigate = useNavigate()
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const { data: faqData ,refetch:getFaqRefetch} = useGetAllFaqQuery();
  const [selectedfile, setSelectedFile] = useState(null);
  const imageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [submitSupport]=usePostHelpandSupportMutation();
  const handleDivClick = () => {
    imageInputRef.current.click();
  };
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage1(file)
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage(null);
    setSelectedImage1(null)

    imageInputRef.current.value = null;
  };
  const removeSelectedFile = () => {
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };
  const handleSubmit=()=>{
    const formData = new FormData();
    formData.append("attachment", selectedfile);
    formData.append("imageFile", selectedImage1);
    formData.append("message", text);


    submitSupport(formData).unwrap().then((res)=>{
      toast.success(res?.message,{position: "bottom-right"})
      setText('');
      setSelectedFile('')
      setSelectedImage('')
      setSelectedImage1('')
    }).catch((err)=>{
      toast.error(err?.data?.message,{position: "bottom-right"})
    })
  }
  console.log(selectedfile, "selected file: ");
const isIpad=useIsIpad()
  return (
    <div className="w-[100%] p-4 bg-rbg h-[93vh] overflow-y-auto">
      <div className={`${isIpad? "w-[690px]" :'w-[930px]'}   mx-auto`}>
        <h1 className="text-2xl font-bold">Hi there Partner</h1>
        <h1 className="text-2xl font-bold">What would you like to know?</h1>
        <p className="mt-5">
          Use one of the most common FAQ to solve the problem else write to us
          and we will revert back to you as soon as possible
        </p>

        <div className="w-full mt-12 flex gap-2 flex-wrap">

          { faqData?.data?.map((item)=>(
            <FAQBox title={item?.question} />
          )) }
        </div>
        <div className="w-full flex justify-between">
          <div onClick={()=>{getFaqRefetch()}} className="flex items-center cursor-pointer mt-2 gap-1">
            <LuRefreshCw /> <h1>Refresh</h1>
          </div>
          <div onClick={()=>{navigate('/faq')}}  className="flex items-center cursor-pointer mt-2 gap-1">
            <IoIosEye />
            <h1>View all</h1>
          </div>
        </div>

        <div className="relative w-[600px] outline-slate-950 outline-offset-1 outline outline-1 mt-10 bg-white  min-h-52  p-2 mx-auto rounded-lg">
          <div className="flex w-full gap-4">
            {selectedImage && (
              <div className="relative w-12 h-12 mb-4">
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full h-full object-cover rounded-lg"
                />
                <button
                  className="absolute -top-2 right-2 h-2 w-2 bg-white rounded-full p-1 shadow-md"
                  onClick={removeSelectedImage}
                >
                  <IoMdClose size={20} />
                </button>
              </div>
            )}
            {selectedfile && (
              <div className="relative flex items-center border w-fit px-2 rounded-md mb-4">
               <div className="flex items-center">
               <MdFilePresent />

                <div className="w-full ">
                  {selectedfile.name?.length > 9
                    ? selectedfile?.name.slice(0, 9) + "..."
                    : selectedfile.name}
                </div>
                    </div>
                <button
                  className="absolute -top-2 right-2 h-2 w-2 bg-white rounded-full p-1 shadow-md"
                  onClick={removeSelectedFile}
                >
                  <IoMdClose size={16} />
                </button>
              </div>
            )}
          </div>

          <textarea
            className="w-full h-full p-2 resize-none border-none focus:outline-none"
            placeholder="Ask whatever you want..."
            onChange={(e) => setText(e.target.value)}
            value={text}
          ></textarea>
          <div className="absolute bottom-2 flex  w-[100%]  px-4 justify-between items-center ">
            <div className="flex gap-4">
              <div
                onClick={handleFileClick}
                className="flex cursor-pointer items-center gap-2"
              >
                <RiAttachment2 />
                <input
                  type="file"
                  accept="*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <h1>Add attachment</h1>
              </div>
              <div>
                <div
                  className="flex cursor-pointer items-center gap-2"
                  onClick={handleDivClick}
                >
                  <FaRegImage />
                  <h1>Use Image</h1>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="flex gap-3 items-center">
              <h1>{text.length} /1000</h1>
              <div onClick={handleSubmit} className="bg-blue-400 cursor-pointer p-2 rounded-lg px-3">
                <FaLongArrowAltRight color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
