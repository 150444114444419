import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { OnBoarding } from "./slices/onBoarding";
import { Dashboard } from "./slices/dashboard"; // Added import for Dashboard
import { setting } from "./slices/Setting";

export const store = configureStore({
  reducer: {
    [OnBoarding.reducerPath]: OnBoarding.reducer,
    [Dashboard.reducerPath]: Dashboard.reducer,
    [setting.reducerPath]:setting.reducer // Ensure Dashboard reducer is included
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([OnBoarding.middleware, Dashboard.middleware,setting.middleware]), // Added Dashboard middleware
});

setupListeners(store.dispatch);