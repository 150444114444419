import React, { useState } from "react";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Cookies from "js-cookie";
import { FaCalendarAlt } from "react-icons/fa";
import Calendar from "../calendar";
import CustomCheckBox from "../CheckBox/customCheckBox";
import NormalDropDown from "../DropDown/NormalDropDown";

const PostGradEdu = ({ onSubmit }) => {
  const methods = useForm();

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">PostGrad Education</h1>
        <p className="text-[#96A0AD] my-2">
        R-World software is designed to empower all aspects of your multi-hotel agency. 
        </p>
      </div>
      <div className="flex justify-between flex-wrap">
        <div className=" w-full">
          <ReusableInput
            label="Course Name"
            name={`courseNamePostGrad`}
            type="text"
            placeholder="Enter Your Course Name"
            rules={{ required: "Course Name is required" }}
          />
        </div>
        <div className="w-full">
        <ReusableInput
            label="Institute Name"
            name={`institudeNamePostGrad`}
            type="text"
            placeholder="Enter Your Institute Name"
            rules={{ required: "Institute Name is required" }}
          />
        </div>
        <div className="w-[48%]">
        <ReusableInput
            label="Starting Year"
            name={`startingYearPostGrad`}
            type="number"
            placeholder="Enter Your Starting Year"
            rules={{ required: "Starting Year is required" }}
          />
        </div>
        <div className=" w-[48%]">
        <ReusableInput
            label="End Year"
            name={`endYearPostGrad`}
            type="number"
            placeholder="Enter Your Ending Year"
            rules={{ required: "Ending Year is required" }}
          />
        </div>


      </div>
    </div>
  );
};

export default PostGradEdu;
