import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useIsIpad } from '../../utils/isIpad';
import ActivityManager from '../../component/ApexGraphComponent/ActivityManager/ActivityManager';
import SingleChainProperty from '../../component/ApexGraphComponent/SingleChainProperty';
import ChannelPerformance from '../../component/ApexGraphComponent/ChannelPerformance';
import TopFiveClients from '../../component/ApexGraphComponent/TopFiveClients';
import CriticalClients from '../../component/ApexGraphComponent/CriticalClients';
import RevenueGrowth from '../../component/ApexGraphComponent/RevenueGrowth';
import ClientByStarCategory from '../../component/ApexGraphComponent/ClientByStarCategory';
import OnboardingFunnel from '../../component/ApexGraphComponent/OnboardingFunnel';

const CRODashboard = () => {
    const isIpad = useIsIpad();
    function getGreeting() {
      const now = new Date();
      const hour = now.getHours();
    
      if (hour >= 5 && hour < 12) {
        return "Good morning";
      } else if (hour >= 12 && hour < 17) {
        return "Good afternoon";
      } else if (hour >= 17 && hour < 20) {
        return "Good evening";
      } else   
     {
        return "Good night";
      }
    }
    const navigate = useNavigate();

  return (
    <div>  <div className="flex justify-center flex-col p-4 items-center">
    <h2>{new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</h2>
      <h1 className="font-semibold text-2xl">{getGreeting()}, Partner!</h1>
    </div>
    <div className="flex justify-between flex-wrap p-4 w-full">
      <div className={`${isIpad?'w-[100%]': 'w-[48%]'} min-h-[300px] `}>
        <ActivityManager />
      </div>
      <div className={` ${isIpad?'w-[49%] my-2': 'w-[25%] '} min-h-[300px] `}>
        <SingleChainProperty />
      </div>
      <div className={`${isIpad?'w-[49%] my-2': 'w-[25%] '}  min-h-[300px] `}>
        <ChannelPerformance />
      </div>
      <div className={`${isIpad?'w-[100%] my-2': 'w-[48%] '}  mt-4 h-[400px]`}>
        <TopFiveClients />
      </div>
      <div className={` ${isIpad?'w-[100%] my-2': 'w-[51%] '}   mt-4 h-[400px] `}>
        <CriticalClients />
      </div>
      <div className={` ${isIpad?'w-[100%] my-2': 'w-[48%] '}  mt-4 h-[400px]`}>
        <RevenueGrowth />
      </div>
      <div className={` ${isIpad?'w-[49%] my-2': 'w-[25%] '} w-[25%] mt-4 h-[400px]`}>
        <ClientByStarCategory />
      </div>
      <div className={` ${isIpad?'w-[49%] my-2': 'w-[25%]'}  mt-4 h-[400px]`}>
        <OnboardingFunnel />
      </div>
    </div></div>
  )
}

export default CRODashboard