import React, { useState, useEffect } from "react";

const CustomCheckBox = ({ label, onChangeCheckbox, checked }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    onChangeCheckbox(label, e);
  }; 

  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        className="h-4 w-4 cursor-pointer appearance-auto rounded-full border"
        onChange={handleChange}
      />
      <span className="text-gray-800 text-lg">{label}</span>
    </label>
  );
};

export default CustomCheckBox;
