// firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD9RXlANr4rLwItnlfO5cXbiWTD-T7gJ_8",
  authDomain: "r-world-34ca9.firebaseapp.com",
  projectId: "r-world-34ca9",
  storageBucket: "r-world-34ca9.appspot.com",
  messagingSenderId: "908257533893",
  appId: "1:908257533893:web:354dde0d8e3f9b401635ac",
  measurementId: "G-GSLYPHD55Z",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
export default app;


