import React from 'react';
import './UniversalLoader.css'; // Ensure you create this CSS file

const UniversalLoader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="backdrop w-[100vh] h-[100vw]">
          <div className="loader">Loading...</div>
        </div>
      )}
    </>
  );
};

export default UniversalLoader;
