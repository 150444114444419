import React from "react";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Cookies from "js-cookie";

const NickName = ({onSubmit}) => {
  const methods = useForm();
  
  const handleSubmit = (data) => {
    onSubmit(data).unwrap().then((res)=>{
      const prevLogin = Cookies.get("recentLogin")
      ? JSON.parse(Cookies.get("recentLogin"))
      : [];

    const isEmailInPrevLogin = prevLogin?.some(
      (item) => item.email === res?.data?.email
    );
    if (!isEmailInPrevLogin) {
      Cookies.set(
        "recentLogin",
        JSON.stringify([
          ...prevLogin,
          { email: res?.data?.email, name: res?.data?.fullName ,profile:res?.data?.profileImg },
        ]),
        { expires: 7 }
      );
 
    }
    });

  };
  const { register, formState: { errors } } = useFormContext();
  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Let's Get Started</h1>
        <p className="text-[#96A0AD] my-2">
          To make your experience as smooth and effcient as possible. we'd love
          to know you a little better. Please enter your details below
        </p>
      </div>
      <div>
        
            <ReusableInput
              label="Full Name"
              name="fullName"
              type="text"
              placeholder="Enter your full name"
              
              rules={{ required: "Name is required" }}
            />{" "}
            <ReusableInput
              label="Enter your preferred Nickname"
              name="preferredNickname"
              type="text"
              placeholder="Enter your nick name"
            />
            <p className="text-sm">
              Note: Leave blank if you prefer to be addressed by Boss!
            </p>
      
      </div>
    </div>
  );
};

export default NickName;
