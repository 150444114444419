import React from "react";
import Skeleton from "../Skeleton";
import Button from "../button";
import UniversalTable from "../Table/UniversalTable";
import { useGetLowPerformanceClientQuery } from "../../redux/slices/dashboard";
import dataNotFound from '../../assets/icons/404/1.svg'

const CriticalClients = () => {
  const { data: performanceData, isLoading } = useGetLowPerformanceClientQuery();

  return (
    <>
      {!isLoading ? (
        <div className="w-[100%] rounded-lg p-4 overflow-hidden bg-white h-[100%]">
          <div className="w-full m-2 flex items-center justify-between gap-2 ">
            <h1 className="font-semibold">Underperforming/Critical Clients </h1>
            {/* <Button>View All</Button> */}
          </div>
          <div className="h-[90%] overflow-y-auto ">
            {performanceData?.data ?<UniversalTable
              dataArray={performanceData?.data}
            />:    <div className="w-[99%] flex bg-[#7c8ba130]  items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
            <img src={dataNotFound} className="h-60 w-60" alt="" />
            <div className="w-full flex justify-center">
      
            <p className="text-xl font-medium">No recent Activities</p>
            </div>
             </div>}
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default CriticalClients;
