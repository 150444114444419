import React, { useState } from "react";
import { LuUser } from "react-icons/lu";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import ReusableInput from "../../../component/InputField/ReusableInput";
import { CgProfile } from "react-icons/cg";
import DropDown from "../../../component/DropDown/DropDown";
import ReusableDropDown from "../../../component/DropDown/DropDown";
import Button from "../../../component/button";
import { BsRecord2 } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import Calendar from "../../../component/calendar";
import { FaCalendarAlt } from "react-icons/fa";

const AddNewSalesEmployee = ({ setShowAddUser }) => {
  const methods = useForm({
    defaultValues: {
      personalDetail: {
        fullName: "",
        email: "",
        phoneNumber: "",
        position: "",
      },
      familyDetail: {
        fathersName: "",
        motherName: "",
        fatherOccuptation: "",
      },
      emergencyDetail: {
        name: "",
        relation: "",
        number: "",
      },
    },
  });
  const [imageURL, setImageURL] = useState();
  const [personalDetail, setPersonalDetail] = useState(true);
  const [familyDetail, setFamilyDetail] = useState(false);
  const [emergencyDetail, setEmergencyDetail] = useState(false);
  const [educationDetail,setEducationDetails]=useState(false);
  const [selectedDirectManager, setSelectedDirectManager] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [dob, setDob] = React.useState(new Date());
  const [selectGraduate, setSelectedGraduate] = useState();

  const handleNext = () => {};

  const directManagerOptions = [
    "Aman Sharma",
    "Shivam Tiwari",
    "Sachin Tiwari",
    "Yukta Mulchandani",
  ];
  const roleOptions = ["Full Time", "Intern"];
  const handleBack = () => {
    if (familyDetail) {
      setPersonalDetail(true);
      setFamilyDetail(false);
    } else if (emergencyDetail) {
      setEmergencyDetail(false);
      setFamilyDetail(true);
    }else if(educationDetail){
      setEmergencyDetail(true);
      setEducationDetails(false)
    }
  };
  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "familyDetail",
  });

  const onSubmit = (data) => {
    data.personalDetail.gender=selectedGender;
    data.personalDetail.role=selectedRole;
    data.personalDetail.DirectManager=selectedDirectManager;
    data.personalDetail.academicYear=selectGraduate
    console.log(data);
  };
console.log("personal Detail ",personalDetail,"family detail ",familyDetail, "emergency detail ",emergencyDetail)
  return (
    <div className="bg-white m-auto border w-1/2  h-full  rounded-md">
  
      <div>
              <div  className="flex gap-3 items-center  p-5">
               <div className="cursor-pointer" onClick={()=>{setShowAddUser(false)}}>
                 <svg
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.1942 3.71209C10.5603 4.0782 10.5603 4.6718 10.1942 5.03791L6.16958 9.0625H16.0938C16.6115 9.0625 17.0312 9.48223 17.0312 10C17.0312 10.5178 16.6115 10.9375 16.0938 10.9375H6.16958L10.1942 14.9621C10.5603 15.3282 10.5603 15.9218 10.1942 16.2879C9.82805 16.654 9.23445 16.654 8.86834 16.2879L3.24334 10.6629C2.87722 10.2968 2.87722 9.7032 3.24334 9.33709L8.86834 3.71209C9.23445 3.34597 9.82805 3.34597 10.1942 3.71209Z"
                  fill="#373737"
                  />
                </svg>
                  </div>
                <p className="text-sm font-medium text-gray-800 ">
                  Personal Information
                </p>
              </div>
              
              <hr />
              <div className="w-full p-2 justify-center items-center">
            <div className="w-full flex justify-center items-center">
              <div className="h-auto  w-[90%] flex justify-between ">
                {!personalDetail && (
                  <Button
                    onClick={handleBack}
                    className="px-4 p-2 w-fit  cursor-pointer border-2 mr-auto"
                  >
                    
                    Back
                  </Button>
                )}
                {  (
                  // <button
                  //   type="submit"
                  //   onClick={() => {
                  //     methods.handleSubmit((data) => {
                  //       onSubmit(data);

                  //       if (personalDetail) {
                  //         setFamilyDetail(true);
                  //         setPersonalDetail(false);
                  //       } else if (familyDetail) {
                  //         setEmergencyDetail(true);
                  //         setFamilyDetail(false);
                  //       }
                  //     })();
                  //   }}
                  //   className="px-4 p-2 w-fit mb-2 self-end cursor-pointer border-2 ml-auto"
                  // >
                  //   Next
                  // </button>
                  <Button type="submit"
                  onClick={() => {
                    methods.handleSubmit((data) => {
                      onSubmit(data);

                      if (personalDetail) {
                        setFamilyDetail(true);
                        setPersonalDetail(false);
                      } else if (familyDetail) {
                        setEmergencyDetail(true);
                        setFamilyDetail(false);
                      }else if(emergencyDetail){
                        setEmergencyDetail(false);
                        setEducationDetails(true)
                      }
                    })();
                  }}
                  className="px-4 p-2 w-fit mb-2 self-end cursor-pointer border-2 ml-auto" > 
                    Next
                  </Button>

                )}
              </div>
            </div>
          </div>
              <div className="lg:px-24 md:px-6 px-4 md:block hidden">
                <div className="flex items-center justify-center mt-6">
                  <div className="flex flex-col justify-center gap-1 items-center">
                 {personalDetail ?  <BsRecord2 color="green" size={36}/>:<FaCheckCircle color="green" size={26}/>}
                    <p className="w-20 text-sm text-center  text-gray-600">
                    Personal Information
                  </p>
                  </div>
                  <div className={`max-w-[196px] ${personalDetail? "bg-indigo-50":"bg-green-400"}  w-full h-0.5 `}/>
                  <div className="flex flex-col justify-center gap-1 items-center">
                   {familyDetail&&<BsRecord2 color="green" size={36}/>}
                   {personalDetail&& <svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
                        fill="#E5E7EB"
                      />
                    </svg>}

                    { ( emergencyDetail|| educationDetail ) && <FaCheckCircle color="green" size={26}/>}

                    <p className="w-20 text-sm text-center text-gray-600">
                    Family Informationas
                  </p>
                  </div>
                  <div className={`max-w-[196px] w-full ${(emergencyDetail||educationDetail)? "bg-green-400" :" bg-indigo-50"} h-0.5 `} />
                  <div className="flex flex-col justify-center items-center gap-2">
                  {emergencyDetail&&<BsRecord2 color="green" size={36}/> }
                    {(personalDetail || familyDetail)&&<svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
                        fill="#E5E7EB"
                      />
                    </svg>}
                    { (  educationDetail ) && <FaCheckCircle color="green" size={26}/>}
                    <p className="w-20 text-sm text-center text-gray-600">
                    Emergency Information
                  </p>
                  </div>
                  <div className={`max-w-[196px] w-full ${educationDetail? "bg-green-400":" bg-indigo-50" } h-0.5 `} />

                  <div className="flex flex-col gap-1 justify-center items-center">
                   {!educationDetail && <svg
                      width={26}
                      height={26}
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z"
                        fill="#E5E7EB"
                      />
                    </svg>}
                    {educationDetail && <BsRecord2 color="green" size={36}/>}
                    <p className="w-20 text-sm text-center text-gray-600">
                    Education Information
                  </p>
                  </div>
                </div>
                
              </div>
              <div className="mt-8 px-6 md:hidden block">
                <div className="dropdown-one w-full rounded outline-none bg-gray-100 border border-gray-200 relative mt-2">
                  <button
                 
                    className="dropbtn-one relative px-5 py-[12px] flex items-center justify-between w-full"
                  >
                    <span
                      className="pr-4 text-gray-600 text-sm font-bold"
                      id="drop-down-content-setter_from_layout_wizard8"
                    >
                      Personal Information
                    </span>
                    <svg
                      width={12}
                      height={8}
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.06216 1.9393L5.43028 7.0368C5.50069 7.11892 5.58803 7.18484 5.68631 7.23003C5.78459 7.27522 5.89148 7.29862 5.99966 7.29862C6.10783 7.29862 6.21472 7.27522 6.313 7.23003C6.41128 7.18484 6.49862 7.11892 6.56903 7.0368L10.9372 1.9393C11.354 1.45273 11.0084 0.701172 10.3678 0.701172H1.63028C0.989656 0.701172 0.644031 1.45273 1.06216 1.9393Z"
                        fill="#1F2937"
                      />
                    </svg>
                  </button>
                  <div
                    className="hidden rounded w-full px-3 py-2 absolute top-16 right-0 bg-white shadow-lg"
                    id="drop-down-div_from_layout_wizard8"
                  >
                    <div
                   
                      className="hover:bg-gray-100 rounded text-gray-600 hover:text-gray-800 p-3 hover:font-bold hover:cursor-default"
                    >
                      <a href="javascript:void(0)" className="cursor-default">
                        <p className="text-sm leading-none">Account Settings</p>
                      </a>
                    </div>
                    <div
                   
                      className="hover:bg-gray-100 rounded text-gray-600 hover:text-gray-800 p-3 hover:font-bold"
                    >
                      <a href="javascript:void(0)" className="cursor-default">
                        <p className="text-sm leading-none">
                          Business Information
                        </p>
                      </a>
                    </div>
                    <div
                  
                      className="hover:bg-gray-100 rounded text-gray-600 hover:text-gray-800 p-3 hover:font-bold"
                    >
                      <a href="javascript:void(0)" className="cursor-default">
                        <p className="text-sm leading-none">Billing Details</p>
                      </a>
                    </div>
                    <div
                  
                      className="hover:bg-gray-100 rounded text-gray-600 hover:text-gray-800 p-3 hover:font-bold"
                    >
                      <a href="javascript:void(0)" className="cursor-default">
                        <p className="text-sm leading-none">Finished</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      <FormProvider {...methods}>
        <form
          className="flex flex-col h-4/5 justify-between items-between"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {personalDetail && (
            <div className="m-6 border-2 p-4">
              <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">
                Personal Details
              </h1>
              <div className="w-24 h-24 rounded-full ">
                <label className=" w-full h-full bg-slate-950  cursor-pointer">
                  {imageURL ? (
                    <div className="w-full flex items-center justify-center h-full object-cover">
                      <img
                        className="w-full  h-full border outline-none border-gray-300 rounded-full"
                        src={imageURL}
                        alt="Uploaded Logo"
                      />
                    </div>
                  ) : (
                    <div className="w-full flex items-center justify-center h-full object-cover">
                      <CgProfile size={70} />
                    </div>
                  )}
                  <input
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.onload = () => {
                        setImageURL(reader.result);
                      };
                      reader.readAsDataURL(file);
                    }}
                    accept="image/* "
                    className="hidden"
                  />
                </label>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <ReusableInput
                  label="Full Name"
                  name="personalDetail.fullName"
                  type="text"
                  placeholder="Enter your Full Name"
                  rules={{ required: "Full Name is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Email"
                  name="personalDetail.email"
                  type="email"
                  placeholder="Enter your email"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Phone Number"
                  name="personalDetail.phoneNumber"
                  type="number"
                  placeholder="Enter your Phone Number"
                  rules={{
                    required: "Phone Number is required",
                    validate: (value) =>
                      value.length === 10 ||
                      "Phone Number must be exactly 10 digits",
                  }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Position"
                  name="personalDetail.position"
                  type="text"
                  placeholder="Enter your Position"
                  rules={{ required: "Position is required" }}
                  showIcon={true}
                  />
                <ReusableDropDown
                  label={"Gender"}
                  selected={selectedGender}
                  setSelected={setSelectedGender}
                  options={["Male", "Female", "Other"]}
                />
                <ReusableDropDown
                  label={"Role"}
                  selected={selectedRole}
                  setSelected={setSelectedRole}
                  options={roleOptions}
                />
                <ReusableDropDown
                  label={"Direct Manager"}
                  selected={selectedDirectManager}
                  setSelected={setSelectedDirectManager}
                  options={directManagerOptions}
                />
                <ReusableDropDown
                  label={"Academic Year"}
                  selected={selectGraduate}
                  setSelected={setSelectedGraduate}
                  options={["Graduate", "Post Graduate"]}
                />
                <div className="w-full ">

                <Calendar
              selectedDate={dob}
              setSelectedDate={setDob}
              backgroundNone={true}
              right={"-left-0"}
              showIconBackground={true}
              onlyPastDaysSelectable={true}
              icon={<FaCalendarAlt /> }
              label="DOB"
            />
                </div>
              </div>
            </div>
          )}
          {familyDetail && (
            <div className="m-6 border-2 p-4">
              <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">Family Details</h1>

              <div className="mb-4">
                <ReusableInput
                  label="Father's Name"
                  name={`familyDetail.fathersName`}
                  type="text"
                  placeholder="Enter your Father's Name"
                  rules={{ required: "Father's Name is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Mother's Name"
                  name={`familyDetail.motherName`}
                  type="text"
                  placeholder="Enter your Mother's Name"
                  rules={{ required: "Mother's Name is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Father's Occupation"
                  name={`familyDetail.fatherOccuptation`}
                  type="text"
                  placeholder="Enter your Mother's Name"
                  rules={{ required: "Mother's Name is required" }}
                  showIcon={true}
                  />
              </div>
            </div>
          )}
          {emergencyDetail && (
            <div className="m-6 border-2 p-4">
              <h1 className="bg-white p-2 w-fit -ml-2 -mt-9">
                Emergency Contact Detail's
              </h1>

              <div className="mb-4">
                <ReusableInput
                  label="Name"
                  name={`emergencyContact.name`}
                  type="text"
                  placeholder="Enter Name of Emergency Contact"
                  rules={{ required: "Father's Name is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Relationship with employee"
                  name={`emergencyContact.relationship`}
                  type="text"
                  placeholder="Enter your Relationship with emergency contact"
                  rules={{ required: "Relationship  is required" }}
                  showIcon={true}
                  />
                <ReusableInput
                  label="Emergency Contact Number"
                  name={`emergencyContact.number`}
                  type="number"
                  placeholder="Enter Emergency Number"
                  rules={{ required: "Emergency Number is required" }}
                  showIcon={true}
                  />
              </div>
            </div>
          )}
      
        </form>
      </FormProvider>
    </div>
  );
};

export default AddNewSalesEmployee;
