// import { emptySplitApi } from "../../injectEndpoint";
import { get } from "react-hook-form";
import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";

export const setting = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addClient:builder.mutation({
      query: (data) => ({
        url: `settings/client/addClient`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body:data
      }),
      invalidatesTags: ["addClient"],
    }),
    addEmployee:builder.mutation({
      query: (data) => ({
        url: `settings/user/addUser`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body:data
      }),
      invalidatesTags: ["addEmployee"],
    }),
    getClient:builder.query({
      query: (data) => ({
        url: `settings/client/getClientsList`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body:data
      }),
      invalidatesTags: ["getClient"],
    }),
    getUsersList:builder.query({
      query: (data) => ({
        url: `settings/user/getUsersList`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body:data
      }),
      invalidatesTags: ["getUsersList"],
    }),
    getClientDetail:builder.query({
      query: (data) => ({
        url: `settings/client/getClientDetails`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params:data
      }),
      invalidatesTags: ["getClientDetail"],
    }),
    getUserDetail:builder.query({
      query: (data) => ({
        url: `settings/user/getUserDetail`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params:data
      }),
      invalidatesTags: ["getUserDetail"],
    }),
    updateClient:builder.mutation({
      query: (data) => ({
        url: `settings/client/updateClient`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data
      }),
      invalidatesTags: ["updateClient"],
    }),
    getDepartment:builder.query({
      query: (data) => ({
        url: `userRoutes/getDepartment`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data
      }),
      invalidatesTags: ["getDepartment"],

    }),
    getUserDropDown:builder.query({
      query: (data) => ({
        url: `settings/user/getUserDropdown`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        body: data
      }),
      invalidatesTags: ["getUserDropDown"],

    })
  }),
  overrideExisting: false, // Add
});
export const {
    useAddClientMutation, useAddEmployeeMutation ,useGetClientQuery,useGetUsersListQuery,useGetClientDetailQuery,useGetUserDetailQuery,useUpdateClientMutation,useGetDepartmentQuery,useGetUserDropDownQuery // Corrected export
} =
  setting; // Corrected export
 