
import React, { useState, useEffect } from 'react'
import OtpInput from '../OTPvarification/otpInput'
import { useResendOTPMutation } from '../../redux/slices/onBoarding';

const OTPVerfication = ({ handleOTPVarification, setCombinedOTP }) => {
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds
  const [canResend, setCanResend] = useState(false);
const [resendotp]=useResendOTPMutation();
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setCanResend(true);
    }
  }, [timeLeft]);

  const handleResend = () => {
    setTimeLeft(120);
    setCanResend(false);
    // Add logic to resend OTP here

    resendotp('');
  };

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Code validation</h1>
        <p className="text-[#96A0AD] my-2">
          Please enter the 4 digit code sent to your mobile number 
        </p>
      </div>
      <div className='w-[100%]'>
        <OtpInput handleOTPVarification={handleOTPVarification}  setCombinedOTP={setCombinedOTP} />
      </div>
      <div className="mt-4 flex justify-between items-center">
        <p>Time left: {Math.floor(timeLeft / 60)}:{('0' + (timeLeft % 60)).slice(-2)}</p>
        <button 
          onClick={handleResend} 
          disabled={!canResend} 
          className={`mt-2 ${canResend ? 'text-blue-500' : 'text-gray-500'}`}
        >
          Resend OTP
        </button>
      </div>
    </div>
  )
}

export default OTPVerfication