import React, { useState } from "react";
import RetvensBlack from "../../assets/icons/RWorldBlack.png";
import ReusableInput from "../InputField/ReusableInput";
import Button from "../button";
import { Form, FormProvider, useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "../../redux/slices/onBoarding";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const methods = useForm();
  const [forgotPassword] = useForgotPasswordMutation();

  const handleSubmitForm = (data) => {
    if(!resetPassword){
        forgotPassword({email:data?.email})
        setResetPassword(true);
    }else{
        window.open("https://mail.google.com/mail/u/0/", "_blank");
    }
  };
  const [resetPassword, setResetPassword] = useState(false);

  const passwordValidationRules = {
    required: "Password is required",
    minLength: {
      value: 12,
      message: "Minimum character allowed is 12",
    },
    validate: {
      hasUpperCase: (value) =>
        /[A-Z]/.test(value) || "One uppercase character required",
      hasLowerCase: (value) =>
        /[a-z]/.test(value) || "One lowercase character required",
      hasNumber: (value) =>
        /[0-9]/.test(value) || "One number required",
      hasSpecialChar: (value) =>
        /[!@#$%^&*(),.?":{}|<>]/.test(value) || "One special character required",
    },
  };

  return (
    <div className="h-[100%] flex flex-col w-[100%]">
      <div className="h-5 w-16 ">
        <img src={RetvensBlack} className="w-[100%] h-[100%]" alt="" />
      </div>
      {!resetPassword &&(
        <div className="flex flex-col gap-2 my-2">
          <h1 className="text-3xl outline-2 font-semibold">Reset Password</h1>
          <p>Enter your email below to receive a password reset link</p>
        </div>
      ) }
      
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
          <div className="my-10">
            {resetPassword ? (
       <div> Password is sent to {methods.getValues("email")}</div>
            ) : (
              <div>
                <ReusableInput
                  label="Your email"
                  name="email"
                  type="text"
                  placeholder="ceo@retvensservices.com"
                  rules={{ required: "Email is required" }}
                  showIcon={true}
                />
                <div className="w-[100%] flex items-end gap-2 flex-col">
                  <p>Don't have an account?</p>
                 <Link to='/signup'> <p className="text-RGreen">Sign Up</p></Link>
                </div>
              </div>
            )}
          </div>
          <Button
            className="w-full bg-RGreen p-2 text-center rounded-lg text-white cursor-pointer"
          >
            {resetPassword ? "Go to Gmail" : "Reset Password"}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default ForgotPassword;