// import { emptySplitApi } from "../../injectEndpoint";
import { get } from "react-hook-form";
import getToken from "../../utils/getToken";
import { emptySplitApi } from "../injectEndpoint";

export const Dashboard = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getrevenueGrowth: builder.query({
      query: () => ({
        url: `dashboard/getRevenueGrowth`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["revenueGrowth"],
    }),
    getActivityManager: builder.query({
      query: () => ({
        url: `dashboard/getActivityManager`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getActivityManager"],
    }),
    getChannelPerformance: builder.query({
        query: (data) => ({
          url: `dashboard/channelPerformance`,
          method: "GET",
          headers: {
            Authorization: getToken(),
          },
          params:data
        }),
        invalidatesTags: ["getChannelPerformance"],
      }),
    getPropertyChain:builder.query({
      query: () => ({
        url: `dashboard/getPropertyClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getPropertyChain"],
    }),
    getStarCategory:builder.query({
      query: () => ({
        url: `dashboard/getStarCategory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getStarCategory"],
    }),
    postHelpandSupport:builder.mutation({
      query: (data) => ({
        url: `helpAndSupport/helpAndSupport`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body:data
      }),
      invalidatesTags: ["postHelpandSupport"],
    }),
    getAllFaq:builder.query({
      query: () => ({
        url: `helpAndSupport/getAllFaq`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getAllFaq"],
    }),
    getHighPerformanceClient:builder.query({
      query: () => ({
        url: `dashboard/highPerformanceClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getClientPerformance"],
    }),
    getLowPerformanceClient:builder.query({
      query: () => ({
        url: `dashboard/lowPerformanceClient`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getClientPerformance"],
    }),
    getOnboardingFunnel:builder.query({
      query: () => ({
        url: `dashboard/getOnboardingFunnel`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getOnboardingFunnel"],
    }),
    getDesignation:builder.query({
      query: (data) => ({
        url: `userRoutes/getDesignation`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
        params:data
      }),
      invalidatesTags: ["getDesignation"],
    })
  }),
  overrideExisting: false, // Add
});
export const { useGetrevenueGrowthQuery, useGetActivityManagerQuery,useGetChannelPerformanceQuery , useGetPropertyChainQuery,useGetStarCategoryQuery,usePostHelpandSupportMutation,useGetAllFaqQuery ,useGetHighPerformanceClientQuery,useGetOnboardingFunnelQuery ,useGetDesignationQuery,useGetLowPerformanceClientQuery} =
  Dashboard; // Corrected export
