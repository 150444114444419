import React from "react";
import ReactApexChart from "react-apexcharts";
import Skeleton from "../Skeleton";
import { useGetOnboardingFunnelQuery } from "../../redux/slices/dashboard";
import dataNotFound from '../../assets/icons/404/6.svg'

const OnboardingFunnel = () => {
    const { data: funnelData , isLoading} = useGetOnboardingFunnelQuery();

    const chartOptions = {
        chart: {
            type: 'donut'
        },
        labels: ['Assigned', 'Created', 'Onboarded', 'Active', 'Inactive'],
        colors: ['#FFA500', '#FF5733', '#D9E3F0', '#4CAF50', '#C0C0C0'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          formatter: function (val, opts) {
              return `<div style="width: 100%; text-align: left;">${val}: ${opts.w.globals.series[opts.seriesIndex]}</div>`;
          }
      },
    };

    const chartSeries = [
        funnelData?.data?.assignedProperty || 0,
        funnelData?.data?.createdProperty || 0,
        funnelData?.data?.onboardedProperty || 0,
        funnelData?.data?.activeProperty || 0,
        funnelData?.data?.inActiveProperty || 0
    ];

    return (
        <>
            {!isLoading ? (
                <div className="w-[100%] rounded-lg p-4 bg-white h-[100%]">
                    <div className="w-full flex items-center gap-2">
                        <h1 className="font-semibold">Onboarding Funnel</h1>
                        {/* <p className="text-xs text-slate-400">Last 7 days</p> */}
                    </div>
                    <div className="h-full flex items-center w-full justify-center">

                   {funnelData?.data ? <ReactApexChart
                        options={chartOptions}
                        series={chartSeries}
                        type="donut"
                        height={300}
                        />: <div className="w-full flex bg-[#7c8ba130] flex-col items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
                        <img src={dataNotFound} className="h-60 w-60" alt="" />
                        <div className="w-full flex justify-center">
                  
                        <p className="text-xl font-medium">No recent Activities</p>
                        </div>
                         </div>}
                        </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </>
    );
};

export default OnboardingFunnel;
