import React, { useState } from "react";
import Button from "../button";
import OtpInput from "./otpInput";
const OTPInputField = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  return (
    <div>
      <form onSubmit={() => {}}>
        <OtpInput length={4}  />
      </form>
    </div>
  );
};

export default OTPInputField;
