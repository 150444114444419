import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import RevensScreen from "../../component/OnBoarding/RevensScreen";
import Button from "../../component/button";
import NickName from "../../component/OnBoarding/NickName";
import HotelManaged from "../../component/OnBoarding/HotelManaged";
import CompanyDetails from "../../component/OnBoarding/CompanyDetails";
import CompanyLocation from "../../component/OnBoarding/CompanyLocation";
import CompanyTeams from "../../component/OnBoarding/CompanyTeams";
import TeamStructure from "../../component/OnBoarding/TeamStructure";
import BillingPreferance from "../../component/OnBoarding/BillingPreferance";
import ConnectWhatsapp from "../../component/OnBoarding/ConnectWhatsapp";
import OTPVerfication from "../../component/OnBoarding/OTPVerfication";
import {
  useBasicDetailsMutation,
  useGoBackMutation,
  useLoginInfoQuery,
  useOTPVerficationMutation,
  useOnBoardingMutation,
} from "../../redux/slices/onBoarding";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ContactList from "../../component/contactList";
import LoadingPage from "./LoadingPage";
import Cookies from "js-cookie";
import { useIsIpad } from "../../utils/isIpad";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import BuisnessInfo from "../../component/OnBoarding/BusinessInfo";
import Address from "../../component/OnBoarding/Address";
import PersonalLocation from "../../component/OnBoarding/PersonalLocation";
import UndergradEdu from "../../component/OnBoarding/UnderGradEdu";
import PostGradEdu from "../../component/OnBoarding/PostGradEdu";

const NewOnBoarding = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //states
  const [combinedOTP, setCombinedOTP] = useState("");
  const [ currentScreen, setCurrentScreen] = useState(1);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedTeamError, setSelectedTeamError] = useState("");
  const [location, setLocation] = useState({});
 
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [locationError, setLocationError] = useState();
  const [personalLocation, setPersonalLocation] = useState();
  const [personalLocationError, setPersonalLocationError] = useState();
  const [teamError, setTeamError] = useState();
  const [customDate, setCustomDate] = useState(new Date()); 
  const [businessinfoError, setBusinessInfoError] = useState();
  const [finalData, setFinalData] = useState({
    department: [],
    currentScreen: currentScreen,
    education: [],
  });
  const [dob, setDob] = useState(new Date());

  const [completeOnBoarding, setCompleteOnBoarding] = useState(false);
  //API
  const [OTPVerfiy] = useOTPVerficationMutation();
  const [onBoarding] = useOnBoardingMutation();
  const [basicDetails] = useBasicDetailsMutation();
  const [goBack]=useGoBackMutation();
  const { data: loginInfo } = useLoginInfoQuery();
  const [designationId,setDesignationId]=useState();
  useEffect(() => {
    setFinalData({ ...finalData, currentScreen: currentScreen });
  }, [currentScreen]);
  //useEffect
  useEffect(() => {
    if (loginInfo?.data) {
      methods.setValue("fullName", loginInfo?.data.fullName);
      methods.setValue("preferredNickname", loginInfo?.data.nickName);
      methods.setValue(
        "companyName",
        loginInfo?.data?.companyDetail?.companyName
      );
      methods.setValue(
        "companyGSTNumber",
        loginInfo?.data?.companyDetail?.companyGSTNumber
      );
      methods.setValue(
        "managedHotel",
        loginInfo?.data?.companyDetail?.managedHotel
      );
      methods.setValue("gender", loginInfo?.data?.profileInformation?.gender);
      methods.setValue(
        "flatNo",
        loginInfo?.data?.profileInformation?.address?.houseNumber
      );
      methods.setValue(
        "streetName",
        loginInfo?.data?.profileInformation?.address?.streetName
      );
      methods.setValue(
        "locality",
        loginInfo?.data?.profileInformation?.address?.locality
      );
      methods.setValue(
        "city",
        loginInfo?.data?.profileInformation?.address?.city
      );
      methods.setValue(
        "country",
        loginInfo?.data?.profileInformation?.address?.country
      );
      setPersonalLocation(loginInfo?.data?.profileInformation?.personalAddress);
      loginInfo?.data?.profileInformation?.education.map((item) => {
        if (item.educationType === "undergraduation") {
          methods.setValue("courseName", item?.courseName);
          methods.setValue("institudeName", item?.institute);
          methods.setValue("startingYear", item?.startYear);
          methods.setValue("endYear", item?.endYear);
        } else if (item?.educationType === "postGraduation") {
          methods.setValue("courseNamePostGrad", item?.courseName);
          methods.setValue("institudeNamePostGrad", item?.institute);
          methods.setValue("startingYearPostGrad", item?.startYear);
          methods.setValue("endYearPostGrad", item?.endYear);
        }
      });

      const dobValue = new Date(loginInfo?.data?.profileInformation?.DOB);
      setDob(isNaN(dobValue) ? new Date() : dobValue);
      setSelectedLanguage(loginInfo?.data?.profileInformation?.language?.length>0 ? loginInfo?.data?.profileInformation?.language:[]);
      methods.setValue("phoneNumber", loginInfo?.data?.phoneNumber);
      setLocation(loginInfo?.data?.companyDetail?.address);
      setSelectedTeam(loginInfo?.data?.companyDetail?.companyTeamSize);
setDesignationId(loginInfo?.data?.designationId)
console.log(loginInfo?.data?.designationId)
      setFinalData({
        ...finalData,
        department: loginInfo?.data?.companyDetail?.department,
        companyGSTNumber: loginInfo?.data?.companyDetail?.companyGSTNumber,
      });

      setCurrentScreen(loginInfo?.data?.currentScreen || 1);
      // setCurrentScreen(14);

      if (loginInfo?.data?.currentScreen === 15) {
        navigate("/loginDashboard");
      } else if (loginInfo?.data?.currentScreen === 16) {
        navigate("/add-team-members");
      }
    }
  }, [loginInfo]);
  
  const validObj =
    currentScreen === 1
      ? { fullName: Yup.string().required("Full name is required") }
      : currentScreen === 2
        ? {
            companyName: Yup.string().required("company name is required"),
            companyGSTNumber: Yup.string()
              .matches(
                /^$|^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                "Invalid GST number"
              )
              .nullable(),
          }
        : currentScreen === 4
          ? { managedHotel: Yup.string().required("Hotel Manage is required") }
          : currentScreen === 8
            ? {
                gender: Yup.string().required("Gender is required"),
              }
            : currentScreen === 9
              ? {
                  flatNo: Yup.string().required("Flat No is required"),
                  streetName: Yup.string().required("Street Name is required"),
                  locality: Yup.string().required("Locality is required"),
                  city: Yup.string().required("city is required"),
                  country: Yup.string().required("Country is required"),
                }
              : currentScreen === 11
                ? {
                    courseName: Yup.string().required(
                      "Course name is required"
                    ),
                    institudeName: Yup.string().required(
                      "institute name is required"
                    ),
                    startingYear: Yup.string()
                      .matches(
                        /^\d{4}$/,
                        "Starting Year must be a 4-digit number"
                      )
                      .required("Starting Year is required"),
                    endYear: Yup.string()
                      .matches(/^\d{4}$/, "End Year must be a 4-digit number")
                      .required("End year is required"),
                  }
                : currentScreen === 12
                  ? {
                      courseNamePostGrad: Yup.string().required(
                        "Course name is required"
                      ),
                      institudeNamePostGrad: Yup.string().required(
                        "institute name is required"
                      ),
                      startingYear: Yup.string()
                        .matches(
                          /^\d{4}$/,
                          "Starting Year must be a 4-digit number"
                        )
                        .required("Starting Year is required"),
                      endYear: Yup.string()
                        .matches(/^\d{4}$/, "End Year must be a 4-digit number")
                        .required("End year is required"),
                    }
                  : currentScreen === 13
                    ? {
                        phoneNumber: Yup.string().required(
                          "Whatsapp Number is required"
                        ),
                      }
                    : "";
  const validationSchemas = Yup.object().shape(validObj);
  const methods = useForm({
    resolver: yupResolver(validationSchemas),
    mode: "onSubmit",
  });

  const nickNameSubmit = (data) => {
    setIsLoading(true);
    basicDetails({ fullName: data.fullName, nickName: data.preferredNickname })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        const prevLogin = Cookies.get("recentLogin")
          ? JSON.parse(Cookies.get("recentLogin"))
          : [];

        setCurrentScreen(res.data.nextScreen);
        const isEmailInPrevLogin = prevLogin.some(
          (item) => item.email === res?.data?.email
        );
        if (!isEmailInPrevLogin) {
          Cookies.set(
            "recentLogin",
            JSON.stringify([
              ...prevLogin,
              {
                email: res?.data?.email,
                name: res?.data?.fullName,
                profile: res?.data?.profileImg,
              },
            ]),
            { expires: 7 }
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);

        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };

  const handleCompanyDetails = (data) => {
    setIsLoading(true);

    setFinalData({
      ...finalData,
      companyName: data?.companyName,
      companyGSTNumber: data?.companyGSTNumber,
      currentScreen: currentScreen,
    });

    onBoarding({
      ...finalData,
      companyName: data?.companyName,
      companyGSTNumber: data?.companyGSTNumber,
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };
  const handleTeamStructureSubmit = (event) => {
    if (!selectedTeam) {
      setSelectedTeamError("Please select a team size.");
      return;
    } else {
      // setCurrentScreen((prev) => prev + 1);
      setIsLoading(true);
      onBoarding(finalData)
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setCurrentScreen(res.data.nextScreen);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Something went wrong", { position: "bottom-right" });
        });
    }
    setSelectedTeamError("");
  };
  const handleCompanyLocation = (data) => {
    debugger
console.log(location,"loca")
    if (!location.fullAddress) {
      
      setLocationError("Please enter a valid address");
      return;
    } else {
    
      setIsLoading(true);
      setFinalData({
        ...finalData,
        address: location,
        currentScreen: currentScreen,
      });
      // setCurrentScreen((prev) => prev + 1);
      setLocationError("");
      onBoarding({
        ...finalData,
        address: location,
        currentScreen: currentScreen,
      })
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setCurrentScreen(res.data.nextScreen);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Something went wrong", { position: "bottom-right" });
        });
    }
  };
  const handleHotelManaged = (data) => {
    setFinalData({
      ...finalData,
      managedHotel: data.managedHotel,
      currentScreen: currentScreen,
    });
    setIsLoading(true);
    onBoarding({
      ...finalData,
      managedHotel: data.managedHotel,
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };
  const handleBillingPreferance = (data) => {
    setIsLoading(true);
    setFinalData({
      ...finalData,
      billingPreference: format(new Date(customDate), "yyyy/MM/dd"),
      currentScreen: currentScreen,
    });
    onBoarding({
      ...finalData,
      billingPreference: format(new Date(customDate), "yyyy/MM/dd"),
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };
  const handleConnectWhatsapp = (data) => {
    setFinalData({
      ...finalData,
      phoneNumber: data.phoneNumber,
      currentScreen: currentScreen,
    });
    setIsLoading(true);
    onBoarding({
      ...finalData,
      phoneNumber: data.phoneNumber,
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };
  const handleOTPVarification = (data) => {
    setIsLoading(true);
    OTPVerfiy({ otp: combinedOTP })
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        setCompleteOnBoarding(true);
      })
      .catch((err) => {
        toast.error(err?.data?.message, { position: "bottom-right" });
        setIsLoading(false);
      });
  };
  const handleCompanyTeams = (data) => {
    if (
      finalData?.department?.length < 1 ||
      !finalData?.department[0]?.businessDepartment
    ) {
      setTeamError("Please enter at least 1 team");
      return;
    } else {
      setIsLoading(true);
      if (finalData?.companyGSTNumber) {
        onBoarding(finalData)
          .unwrap()
          .then((res) => {
            setCurrentScreen(res.data.nextScreen);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error("Something went wrong", { position: "bottom-right" });
          });
      } else {
        setIsLoading(true);
        onBoarding(finalData)
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            setCurrentScreen(res.data.nextScreen);
          });
      }
    }
  };
  const handleBuisnessInfo = (data) => {
    if (selectedLanguage?.length < 1) {
      setBusinessInfoError("Please select at least 1 language");
      return;
    } else if (!dob) {
      setBusinessInfoError("Please enter a valid date of birth");
      return;
    } else {
      setIsLoading(true);
      setFinalData({
        ...finalData,
        language: selectedLanguage,
        gender: data?.gender,
        DOB: dob,
      });

      onBoarding({
        ...finalData,
        language: selectedLanguage,
        gender: data?.gender,
        DOB: dob,
        currentScreen: currentScreen,
      })
        .unwrap()
        .then((res) => {
          setIsLoading(false);

          setCurrentScreen(res.data.nextScreen);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Something went wrong", { position: "bottom-right" });
        });
    }
  };
  const handleAddress = (data) => {
    setFinalData({
      ...finalData,
      userAddress: {
        houseNumber: data?.flatNo,
        streetName: data?.streetName,
        locality: data?.locality,
        city: data?.city,
        country: data?.country,
      },
    });
    setIsLoading(true);

    onBoarding({
      ...finalData,
      userAddress: {
        houseNumber: data?.flatNo,
        streetName: data?.streetName,
        locality: data?.locality,
        city: data?.city,
        country: data?.country,
      },
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);

        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };

  const handlePersonalAddress = (data) => {
    if (!personalLocation?.fullAddress) {
      setPersonalLocationError("Please enter a valid address");
      return;
    } else {
      setFinalData({
        ...finalData,
        personalAddress: personalLocation?.fullAddress,
      });
      setIsLoading(true);

      onBoarding({
        ...finalData,
        personalAddress: personalLocation?.fullAddress,
        currentScreen: currentScreen,
      })
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          setPersonalLocationError("");
          setCurrentScreen(res.data.nextScreen);
        })
        .catch((err) => {
          setIsLoading(false);
          setPersonalLocationError("");
          toast.error("Something went wrong", { position: "bottom-right" });
        });
    }
  };
  const handleUnderGrad = (data) => {
    setFinalData({
      ...finalData,
      education: [
        ...finalData.education,
        {
          educationType: "underGraduation",
          courseName: data?.courseName,
          institute: data.institudeName,
          startYear: data.startingYear,
          endYear: data.endYear,
        },
      ],
    });
    setIsLoading(true);

    onBoarding({
      ...finalData,
      education: [
        // ...finalData.education,
        {
          educationType: "underGraduation",
          courseName: data?.courseName,
          institute: data?.institudeName,
          startYear: data?.startingYear,
          endYear: data?.endYear,
        },
      ],
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);

        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };
  const handlePostGrad = (data) => {
    setFinalData({
      ...finalData,
      education: [
        ...finalData.education,
        {
          educationType: "postGraduation",
          courseName: data?.courseNamePostGrad,
          institute: data?.institudeNamePostGrad,
          startYear: data?.startingYearPostGrad,
          endYear: data?.endYearPostGrad,
        },
      ],
    });
    setIsLoading(true);

    onBoarding({
      ...finalData,
      education: [
        {
          educationType: "postGraduation",
          courseName: data?.courseNamePostGrad,
          institute: data?.institudeNamePostGrad,
          startYear: data?.startingYearPostGrad,
          endYear: data?.endYearPostGrad,
        },
      ],
      currentScreen: currentScreen,
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false);

        setCurrentScreen(res.data.nextScreen);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };

  const handleNext = (data) => {
    console.log("handleNext called, currentScreen:");
    switch (currentScreen) {
      case 1:
        nickNameSubmit(data);
        break;
      case 2:
        handleCompanyDetails(data);

        break;
      case 3:
        handleTeamStructureSubmit(data);
        break;
      case 4:
        handleHotelManaged(data);
        break;
      case 5:
        handleCompanyLocation(data);
        break;
      case 6:
        handleCompanyTeams(data);
        break;
      case 7:
        handleBillingPreferance(data);
        break;
      case 8:
        handleBuisnessInfo(data);
        break;

      case 9:
        handleAddress(data);
        break;
      case 10:
        handlePersonalAddress(data);
        break;
      case 11:
        handleUnderGrad(data);
        break;
      case 12:
        console.log("Calling handlePostGrad");
        handlePostGrad(data);
        break;
      case 13:
        handleConnectWhatsapp(data);
        break;

      case 14:
        handleOTPVarification(data);
        break;
    }
  };

  const handleBack = () => {
    setIsLoading(true);

    goBack({
      designationId:designationId,
      currentScreen:currentScreen
    }).unwrap().then((res)=>{
      setCurrentScreen(res?.data?.currentScreen);
    setIsLoading(false);

    }).catch((err)=>{
      toast.error(err?.data?.message,{position: "bottom-right"})
    setIsLoading(false);

    })
    
    // if (currentScreen > 1) {
    //   if (currentScreen == 8 && !finalData?.companyGSTNumber) {
    //     setCurrentScreen((prev) => prev - 2);
    //   } else {
    //     setCurrentScreen((prev) => prev - 1);
    //   }
    // }
  };
  console.log(personalLocation, "personalLocation");
  const isIpad = useIsIpad();
  return (
    <>
      <UniversalLoader loading={isLoading} />
      {!completeOnBoarding ? (
        <div className="w-[100vw] h-[100vh] flex  p-4">
          <div className="w-[50vw] h-[100%]">
            <RevensScreen currentScreen={currentScreen} />
          </div>
          <ContactList />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              <div className="flex justify-center items-center w-[50vw] h-[100%] p-6 relative">
                <div className="relative w-[80%] h-auto">
                  <div
                    className={` ${isIpad ? " h-[70vh] w-[100%]" : "items-start flex  flex-col justify-center h-[100vh] w-[60%]"}   pt-16 pb-12`}
                  >
                    <div className="h-8 w-fit mb-5 flex justify-center">
                      {currentScreen !== 1 && (
                        <div
                          type="RDark"
                          onClick={handleBack}
                          className={`flex items-center  justify-center  text-[14px] font-bold leading-none px-5 h-10  rounded-[9px] w-full cursor-pointer shadow-custom bg-RDark text-white  `}
                        >
                          Go back
                        </div>
                      )}
                    </div>
                    {currentScreen === 1 ? (
                      <div className="w-auto h-auto">
                        <NickName onSubmit={handleNext} />
                      </div>
                    ) : currentScreen === 2 ? (
                      <div className="w-auto h-auto">
                        <CompanyDetails
                          finalData={finalData}
                          setFinalData={setFinalData}
                          onSubmit={handleNext}
                        />
                      </div>
                    ) : currentScreen === 3 ? (
                      <div className="w-auto h-auto">
                        <TeamStructure
                          finalData={finalData}
                          setFinalData={setFinalData}
                          handleTeamStructureSubmit={handleTeamStructureSubmit}
                          selectedTeam={selectedTeam}
                          selectedTeamError={selectedTeamError}
                          setSelectedTeam={setSelectedTeam}
                          setSelectedTeamError={setSelectedTeamError}
                        />
                      </div>
                    ) : currentScreen === 4 ? (
                      <div className="w-auto h-auto">
                        <HotelManaged
                          finalData={finalData}
                          setFinalData={setFinalData}
                        />
                      </div>
                    ) : currentScreen === 5 ? (
                      <div className="w-auto h-auto">
                        <CompanyLocation
                          finalData={finalData}
                          setFinalData={setFinalData}
                          location={location}
                          setLocation={setLocation}
                          locationError={locationError}
                          setLocationError={setLocationError}
                          loginInfo={loginInfo}
                        />
                      </div>
                    ) : currentScreen === 6 ? (
                      <div className="w-auto h-auto">
                        <CompanyTeams
                          finalData={finalData}
                          setFinalData={setFinalData}
                          teamError={teamError}
                          setTeamError={setTeamError}
                          loginInfo={loginInfo}
                        />
                      </div>
                    ) : currentScreen === 7 && finalData?.companyGSTNumber ? (
                      <div className="w-auto h-auto">
                        <BillingPreferance
                          customDate={customDate}
                          setCustomDate={setCustomDate}
                        />
                      </div>
                    ) : currentScreen === 8 ? (
                      <div className="w-auto h-auto">
                        <BuisnessInfo
                          selectedLanguage={selectedLanguage}
                          businessinfoError={businessinfoError}
                          setSelectedLanguage={setSelectedLanguage}
                          dob={dob}
                          setDob={setDob}
                        />
                      </div>
                    ) : currentScreen === 9 ? (
                      <div className="w-auto h-auto">
                        <Address />
                      </div>
                    ) : currentScreen == 10 ? (
                      <div className="w-auto h-auto">
                        <PersonalLocation
                          location={personalLocation}
                          setLocation={setPersonalLocation}
                          personalLocationError={personalLocationError}
                          loginInfo={loginInfo}
                        />
                      </div>
                    ) : currentScreen == 11 ? (
                      <div className="w-auto h-auto">
                        <UndergradEdu />
                      </div>
                    ) : currentScreen == 12 ? (
                      <div className="w-auto h-auto">
                        <PostGradEdu />
                      </div>
                    ) : currentScreen == 13 ? (
                      <div className="w-auto h-auto">
                        <ConnectWhatsapp />
                      </div>
                    ) : currentScreen == 14 ? (
                      <div className="w-auto h-auto">
                        <OTPVerfication
                          handleOTPVarification={handleOTPVarification}
                          setCombinedOTP={setCombinedOTP}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className=" mt-5 w- h-8 w-[100%] flex items-center justify-between">
                      <Button className=" bg-blue-500 text-white rounded">
                        Save & go next
                      </Button>
                      {currentScreen == 12 && (
                        <div
                          onClick={() => {
                            setCurrentScreen((prev) => prev + 1);
                          }}
                          className=" items-center  text-[14px] font-semibold leading-none px-5 h-10  rounded-[9px] w-fit cursor-pointer shadow-custom p-2 flex itmes-center justify-center bg-RGreen text-white "
                        >
                          Skip
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

export default NewOnBoarding;
