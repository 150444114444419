import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import SideBar from "../../component/SideBar";
import TeamDetails from "./Sales/TeamDetails";
import Support from "../Support/Support";
import FAQs from "../Support/FAQs";
import MainDashboard from "../MainDashboard";
import AllClient from "../AllClients/AllClient";
import AllEmployees from "../employees/AllEmployees";


const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState("dashboard");
  

  return (
    <div className="w-full">
     
      <div className="flex w-full">
      
        <div className=" w-full h-[93vh] overflow-y-auto ">
          {selectedItem == "dashboard" && <MainDashboard />}
          {selectedItem == "salesTeam" && <TeamDetails />}
          {selectedItem == "support" && (
            <Support setSelectedItem={setSelectedItem} />
          )}
          {selectedItem == "FAQs" && <FAQs />}
          {selectedItem == "All client" && <AllClient />}
          {selectedItem == "Employees" && <AllEmployees />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
