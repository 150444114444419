import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Button from "../../component/button";
import ProfilePicture from "../../assets/icons/Revens/ProfilePicture.png";
import UserProfileWrapper from "../../component/WrapperComponents/UserProfileWrapper";
import {
  useGetClientDetailQuery,
  useGetUserDetailQuery,
} from "../../redux/slices/Setting";
import AddEmployee from "../employees/AddEmployee";
import { useNavigate, useParams } from "react-router-dom";
const UserDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data } = useGetUserDetailQuery({userId:userId});
  const [userData, setUserData] = useState();
  const [showAddEmployee, setShowAddEmployee] = useState(false);
 
  useEffect(() => {
    setUserData(data?.data);
  }, [data]);

  return (
    <>
      <div className="p-6 w-full min-h-[100%] bg-rbg">
        {!showAddEmployee ? (
          <div className="h-auto  w-full">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-4 ">
                <div onClick={()=>{navigate(-1)}} className="p-2 cursor-pointer bg-blue-200 rounded-lg">
              <FaLongArrowAltLeft />
            </div>
                <div>
                  <h1 className="font-bold text-xl">{userData?.fullName}</h1>
                  <h1 className="font-semibold text-base">Revenue Manager</h1>
                </div>
              </div>
              <Button
                onClick={() => {
                  setShowAddEmployee(true);
                }}
              >
                Edit Details
              </Button>
            </div>
            {/* <div className="flex mt-8 p-4 rounded-lg gap-4 w-full bg-white">
              <div>Staff Profile</div>
              <div>Hotel Assigned</div>
            </div> */}
            <div className="flex mt-8 p-4 w-full rounded-lg gap-4  bg-white">
              <div className=" flex w-full gap-10 justify-between">
                <div className="bg-red-400 rounded-full w-16 h-16 flex items-center">
                  <img src={ProfilePicture} className="object-fit" alt="" />
                </div>
                <div className="w-full flex justify-between ">
                <div className="ml-20 w-[60%] flex  items-center justify-between">
                <div className="flex  flex-col justify-between ">

                  <div className="flex gap-2 font-bold">
                    <h1 className="text-neutral-500 ">Employee Id: </h1>
                    <p>{userData?.userId}</p>
                  </div>
                  <div className="flex gap-2 font-bold">
                    <h1 className="text-neutral-500 ">Department: </h1>
                    <p>Revenue Management</p>
                  </div>
                </div>
                <div className="flex flex-col justify-between ">
                  <div className="flex gap-2 font-bold">
                    <h1 className="text-neutral-500 ">Phone Number: </h1>
                    <p>{userData?.phoneNumber}</p>
                  </div>
                  <div className="flex gap-2 font-bold">
                    <h1 className="text-neutral-500 ">Email Id: </h1>
                    <p>{userData?.email}</p>
                  </div>
                </div>
                </div>

              </div>
              </div>
            </div>
            <div className="flex mt-8 w-full  justify-between h-full">
              <div className="w-[35%]">
                <UserProfileWrapper label={"Profile Information"}>
                  <div className="flex mt-2 flex-wrap">
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Gender</h1>
                      <p className="font-semibold">{userData?.gender}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Date of Birth
                      </h1>
                      <p className="font-semibold">{userData?.DOB}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Identification code
                      </h1>
                      <p className="font-semibold">
                        {userData?.identificationCode}
                      </p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Nationality
                      </h1>
                      <p className="font-semibold">{userData?.nationality}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Hometown</h1>
                      <p className="font-semibold">{userData?.hometown}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Hometown</h1>
                      <p className="font-semibold">Kanpur</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Language</h1>
                      <p className="font-semibold flex ">
                        {userData?.language.map((item) => (
                          <div>{item},</div>
                        ))}
                      </p>
                    </div>{" "}
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Maritial Status
                      </h1>
                      <p className="font-semibold">
                        {userData?.maritialStatus}
                      </p>
                    </div>
                    <div className="w-full mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Permanent Address
                      </h1>
                      <p className="font-semibold">
                        {userData?.address?.permanentAddress}
                      </p>
                    </div>
                    <div className="w-full mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Current Address
                      </h1>
                      <p className="font-semibold">
                        {userData?.address?.currentAddress}
                      </p>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
              <div className="w-[60%] flex flex-col gap-6">
                <UserProfileWrapper label={"Education Information"}>
                  {userData?.education.map((item) => (
                    <div className="w-full flex justify-between mt-2">
                      <div className="w-1/2">
                        <h1 className=" font-semibold">{item?.course}</h1>
                        <p className="font-semibold text-slate-500">
                          {item?.institute}
                        </p>
                      </div>

                      <h1 className=" font-semibold">
                        {item?.startingYear}-{item?.endYear}
                      </h1>
                    </div>
                  ))}
                </UserProfileWrapper>
                <UserProfileWrapper label={"Account Information"}>
                  <div className="flex mt-2 flex-wrap">
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Gender</h1>
                      <p className="font-semibold">Female</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Date of Birth
                      </h1>
                      <p className="font-semibold">5th March, 1896</p>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
            </div>
          </div>
        ) : (
          <AddEmployee
            setShowAddEmployee={setShowAddEmployee}
            editEmployee={true}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default UserDetail;
