import React, { useState, useRef, useEffect } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const NormalDropDown = ({
  options,
  label,
  selectedValue,
  setSelectedValue,
  isMulti = false,
  sendOnlyId = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (value) => {
    if (isMulti) {
      if (selectedValue?.includes(value)) {
        setSelectedValue(selectedValue.filter((item) => item !== value));
      } else {
        setSelectedValue([...selectedValue, value]);
      }
    } else {
      setSelectedValue(value);
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getDisplayValue = () => {
    if (isMulti) {
      if (selectedValue?.length === 0) return label;
      if (selectedValue?.length > 3) {
        return (
          selectedValue
            .slice(0, 2)
            ?.map((id) => {
              const option = options?.find((opt) => opt.id === id);
              return option ? option.name : id;
            })
            .join(", ") +
          " +" +
          (selectedValue?.length - 2) +
          " Others"
        );
      }
      return selectedValue
        ?.map((id) => {
          const option = options?.find((opt) => opt.id === id);
          return option ? option.name : id;
        })
        .join(", ");
    } else {
      if (sendOnlyId) {
        const option = options?.find((opt) => opt.id === selectedValue);
        return option ? option.name : selectedValue;
      }
      return selectedValue || label;
    }
  };

  return (
    <div className="relative w-full h-full" ref={dropdownRef}>
      <div
        className={`flex justify-between items-center w-full h-full p-2 text-gray-700 bg-white text-xs border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 ${
          selectedValue === "" || (isMulti && selectedValue?.length === 0)
            ? "text-gray-500"
            : ""
        }`}
        onClick={toggleDropdown}
      >
        {getDisplayValue() || label}
        {isOpen ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
      </div>
      {isOpen && (
        <div className="absolute z-[999] mt-1 w-full bg-white border border-gray-300 max-h-[200px] overflow-y-auto rounded-md shadow-lg">
          {options?.map((option, index) => (
            <div
              key={index}
              className={`p-2 text-xs z-[999] text-gray-700 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white ${
                isMulti &&
                selectedValue?.includes(sendOnlyId ? option.id : option)
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              onClick={() => handleSelect(sendOnlyId ? option.id : option)}
            >
              {sendOnlyId ? option?.name : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NormalDropDown;
