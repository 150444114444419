import React, { useState } from "react";
import RocketIcon from "../../assets/icons/Rocket.svg";
import Login from "../../component/OnBoarding/Login";
import SignUp from "../../component/OnBoarding/SignUp";
import { useLocation, useNavigate } from "react-router-dom";
import RecentLoginCard from "../../component/RecentLoginCard/RecentLoginCard";
import Cookies from "js-cookie";
import getToken from "../../utils/getToken";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import { useIsIpad } from "../../utils/isIpad";
// import { navigate } from "@storybook/addon-links/*";

const LoginPage = () => {
  const location = useLocation();
  const recentLoginData = Cookies.get("recentLogin");
  const [loading, setLoading] = useState(false);
  const [recentLogin, setRecentLogin] = useState(
    recentLoginData ? JSON.parse(recentLoginData) : []
  );

  const [recentLoginEmail, setRecentLoginEmail] = useState("");

  const handleRecentLoginClick = (email) => {
    setRecentLoginEmail(email);
  };
  const isIpad = useIsIpad();

  return (
    <div className="w-[100vw] min-h-[100vh]  overflow-hidden">
      <UniversalLoader loading={loading} />
      <div
        className={`flex items-center bg-RGreen 0 h-[50%] w-[100%] ${isIpad ? "p-5 " : "p-24"}`}
      >
        <div className=" flex mx-10 py-10  md:gap-6 flex-col h-[100%] w-[100%] md:w-1/3">
          <div className="flex flex-col  text-white">
            <h1 className=" text-xl md:text-2xl font-bold">
              Welcome to R-World
            </h1>
            <p className=" text-sm md:text-2xl ">
              Don't just take our word for it – experience the transformation
              for yourself!
            </p>
          </div>
          <div className="text-white text-sm md:text-base font-[13px]">
            From personalized revenue strategies to operational efficiency and
            beyond
          </div>
        </div>
        <div
          className={` md:absolute ${isIpad ? " left-[400px] h-[285px] w-[285px]" : " left-[700px] h-[385px] w-[385px]"}    hidden md:flex `}
        >
          <img src={RocketIcon} alt="" />
        </div>

        <div
          className={`absolute ${isIpad ? " w-[800px ]  top-[10vh] right-12" : " w-[500px]  top-[15vh] right-16"} `}
        >
          {location.pathname === "/signup" ? (
            <SignUp setLoading={setLoading} setRecentLogin={setRecentLogin} />
          ) : (
            <Login recentLoginEmail={recentLoginEmail} setLoading={setLoading} setRecentLogin={setRecentLogin} />
          )}
        </div>
      </div>
      {location.pathname === "/login" && (
        <div className="hidden  md:flex p-4 flex-col gap-4">
          <h1 className="text-2xl font-bold">Recent Login</h1>
          <div className="flex gap-4 h-auto w-[700px] flex-wrap">
            {recentLogin?.map((item, index) => (
              <div
                onClick={() => {
                  handleRecentLoginClick(item?.email);
                }}
              >
                <RecentLoginCard
                  setRecentLogin={setRecentLogin}
                  recentLogin={recentLogin}
                  index={index}
                  name={item?.name}
                  email={item?.email}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
