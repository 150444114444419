import React, { useEffect, useState } from "react";
import CustomWrapper from "../../component/dividedScreen";
import SelectableTabs from "../../component/SelectableTabls";
import Scenario from "./Scenario";
import {
  useGetAllCategoryQuery,
  useGetScenarioDetailsQuery,
} from "../../redux/slices/onBoarding";
import UniversalLoader from "../../component/Loader/UniversalLoader";
import { useIsIpad } from "../../utils/isIpad";

const NewLoginDashboard = () => {
  const { data: categoryData, refetch: refetchCategoryData } =
    useGetAllCategoryQuery();
  const isIpad = useIsIpad();
  const [categoryId, setCategoryId] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: senarioData,
    isLoading: senarioDataLoading,
    isError,
    error,
  } = useGetScenarioDetailsQuery(
    {
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    },
    { skip: !(categoryId && subCategoryId) }
  );

  useEffect(() => {
    setSubCategoryArray(
      categoryData?.data.flatMap((category) =>
        category?.categories?.flatMap((subCategory) =>
          subCategory?.subCategories
            .filter((sub) => !sub.completed)
            .map((sub) => sub.subCategoryId)
        )
      )
    );
  }, [categoryData]);

  useEffect(() => {
    if (
      categoryData?.data &&
      subCategoryArray &&
      !categoryId &&
      !subCategoryId
    ) {
      setCategoryId(categoryData?.data[0]?.categories[0]?.categoryId);
      setSubCategoryId(
        subCategoryArray[0]
          ? subCategoryArray[0]
          : categoryData?.data[0]?.categories[0]?.subCategories[0]?.subCategoryId
      );
    }
  }, [subCategoryArray]);

  return (
    <CustomWrapper isBubble={true}>
      <UniversalLoader loading={isLoading || senarioDataLoading} />
      <div
        className={`absolute  left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  border-2    flex justify-center ${isIpad ? "w-[1000px] h-[600px] " : "w-[1300px] h-[700px] "}`}
      >
        <div className="w-[30%]  border-r-2  h-full">
          <SelectableTabs
            categoryData={categoryData}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            setCategoryId={setCategoryId}
            setSubCategoryId={setSubCategoryId}
          />
        </div>
        <div className="w-[70%]  border-r-2  h-full">
          <Scenario
            setIsLoading={setIsLoading}
            subCategoryArray={subCategoryArray}
            setSubCategoryId={setSubCategoryId}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            senarioDetail={senarioData}
            refetchCategoryData={refetchCategoryData}
          />
        </div>
      </div>
    </CustomWrapper>
  );
};

export default NewLoginDashboard;
