import React from "react";
import { CiFilter } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Skeleton from "../../Skeleton";
import ActivityWrapper from "./ActivityWrapper";
import { useGetActivityManagerQuery } from "../../../redux/slices/dashboard";
import Chart from "react-apexcharts";
import '../../../utils/hideScroll.css'
import dataNotFound from '../../../assets/icons/404/1.svg'

const ActivityManager = () => {
  const { data: activityManagerData, isLoading } = useGetActivityManagerQuery();

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      width: "100%",
      height: "100%",
    },
    stroke: {
      width: 2,
      curve: "straight",
    },
    xaxis: {
      show: false,
      categories: [], // Empty initially, will be set later
      labels: {
        style: {
          colors: "#94A3B8", // Tailwind color slate-400
          fontSize: "0px", // Adjust font size as needed
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: "#94A3B8", // Tailwind color slate-400
          fontSize: "12px", // Adjust font size as needed
        },
        formatter: function (value) {
          return value >= 1000
            ? value.toFixed(0) / 1000 + "k"
            : value.toFixed(2);
        },
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false, // Disable horizontal grid lines
        },
      },
      xaxis:{
        lines: {
          show: false, // Disable vertical grid lines
        },
      }
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: "100%",
          },
          xaxis: {
            labels: {
              show: true,
              rotate: -45,
            },
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Value",
      data: [], // Empty initially, will be set later
    },
  ];
  const chartData = [
    {
      label: "Revenue",
      data: activityManagerData?.data?.revenueChartData,
      value: activityManagerData?.data?.ActivityManagerData?.["Revenue"],
    },
    {
      label: "ADR",
      data: activityManagerData?.data?.ADRChartData,
      value: activityManagerData?.data?.ActivityManagerData?.["ADR"],
    },
    {
      label: "Room Nights",
      data: activityManagerData?.data?.roomChartData,
      value: activityManagerData?.data?.ActivityManagerData?.["Room Nights"],
    },
    {
      label: "BOB",
      data: activityManagerData?.data?.BOBChartData,
      value: activityManagerData?.data?.ActivityManagerData?.["BOB"],
    },
  ];

  return (
    <>
  {!isLoading ? (
  <div className="w-full rounded-lg p-4 bg-white h-full flex flex-col justify-between pb-[40px]">
    <div className="w-full flex justify-between">
      <h1 className="font-semibold">Activity Manager</h1>
      {/* <div className="flex items-center gap-3">
        <div className="flex items-center">
          <CiFilter /> Filter
        </div>
        <div>
          <IoIosInformationCircleOutline />
        </div>
      </div> */}
    </div>
  {activityManagerData ? 
  <div
      className="w-full gap-2 h-[200px]   flex mt-4"
    >
      {chartData?.map((item, index) => (
        <div key={index} className="w-[24%] h-full">
          <ActivityWrapper label={item?.label} value={item?.value}>
            <Chart
              options={{
                ...options,
                xaxis: {
                  ...options.xaxis,
                  categories: item?.data?.date || [],
                },
              }}
              series={[{ ...series[0], data: item?.data?.value||[] }]}
              type="line"
              height={"50%"}
              width={"100%"}
            />
          </ActivityWrapper>
        </div>
      ))}
    </div> :  <div className="w-full flex bg-[#7c8ba130]  items-center justify-start  h-[90%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
      <img src={dataNotFound} className="h-60 w-60" alt="" />
      <div className="w-full flex justify-center">

      <p className="text-xl font-medium">No recent Activities</p>
      </div>
       </div>}
  </div>
) : (
  <Skeleton />
)}
    </>
  );
};

export default ActivityManager;
