import { useEffect, useState } from "react";

export const useIsIpad = () => {
  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const checkIfIpad = () => {
      const isPortrait = () =>
        window.matchMedia("(orientation: portrait)").matches;
      const isLandscape = () =>
        window.matchMedia("(orientation: landscape)").matches;
//Ipad (8th generation) and similar models
      const iPad8thGenPortraitWidth = 707;
      const iPad8thGenPortraitHeight = 1080;
      const iPad8thGenLandscapeWidth = 1080;
      const iPad8thGenLandscapeHeight = 707;

//Ipad (8th generation) chrome version and similar modals
const iPad8thGenPortraitWidthChrome = 695;
const iPad8thGenPortraitHeightChrome = 1080;
const iPad8thGenLandscapeWidthChrome = 1080;
const iPad8thGenLandscapeHeightChrome = 695;
      // iPad Mini (6th generation) and similar models
      const iPadMiniPortraitWidth = 768;
      const iPadMiniPortraitHeight = 1024;
      const iPadMiniLandscapeWidth = 1024;
      const iPadMiniLandscapeHeight = 768;

      // iPad Air (4th generation) and similar models
      const iPadAirPortraitWidth = 820;
      const iPadAirPortraitHeight = 1180;
      const iPadAirLandscapeWidth = 1180;
      const iPadAirLandscapeHeight = 820;

      // iPad Pro 11-inch (3rd generation) and similar models
      const iPadPro11PortraitWidth = 834;
      const iPadPro11PortraitHeight = 1194;
      const iPadPro11LandscapeWidth = 1194;
      const iPadPro11LandscapeHeight = 834;

      // iPad Pro 12.9-inch (5th generation) and similar models
      const iPadPro12PortraitWidth = 1024;
      const iPadPro12PortraitHeight = 1366;
      const iPadPro12LandscapeWidth = 1366;
      const iPadPro12LandscapeHeight = 1024;

      // Actual screen dimensions
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Check if current dimensions match any known iPad model
      const isIpadModel =
        (isPortrait() &&
          ((screenWidth === iPadMiniPortraitWidth &&
            screenHeight === iPadMiniPortraitHeight) ||
            (screenWidth === iPadAirPortraitWidth &&
              screenHeight === iPadAirPortraitHeight) ||
            (screenWidth === iPadPro11PortraitWidth &&
              screenHeight === iPadPro11PortraitHeight) ||
            (screenWidth === iPadPro12PortraitWidth &&
              screenHeight === iPadPro12PortraitHeight) ||
            (screenWidth === iPad8thGenPortraitWidth &&
              screenHeight === iPad8thGenPortraitHeight)||
              (screenWidth === iPad8thGenPortraitWidthChrome &&
                screenHeight === iPad8thGenPortraitHeightChrome))) ||
        (isLandscape() &&
          ((screenWidth === iPadMiniLandscapeWidth &&
            screenHeight === iPadMiniLandscapeHeight) ||
            (screenWidth === iPadAirLandscapeWidth &&
              screenHeight === iPadAirLandscapeHeight) ||
            (screenWidth === iPadPro11LandscapeWidth &&
              screenHeight === iPadPro11LandscapeHeight) ||
            (screenWidth === iPadPro12LandscapeWidth &&
              screenHeight === iPadPro12LandscapeHeight) ||
            (screenWidth === iPad8thGenLandscapeWidth &&
              screenHeight === iPad8thGenLandscapeHeight)||
              (screenWidth === iPad8thGenLandscapeWidthChrome &&
                screenHeight === iPad8thGenLandscapeHeightChrome)));

      setIsIpad(isIpadModel);
    };

    checkIfIpad(); // Initial check
    window.addEventListener("resize", checkIfIpad); // Listen for resize events
    window.addEventListener("orientationchange", checkIfIpad); // Listen for orientation change events

    return () => {
      window.removeEventListener("resize", checkIfIpad); // Clean up resize listener
      window.removeEventListener("orientationchange", checkIfIpad); // Clean up orientation change listener
    };
  }, []);

  return isIpad;
};
