import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Button from "../../component/button";
import ProfilePicture from "../../assets/icons/Revens/ProfilePicture.png";
import UserProfileWrapper from "../../component/WrapperComponents/UserProfileWrapper";
import {
  useGetClientDetailQuery,
  useGetUserDetailQuery,
} from "../../redux/slices/Setting";
import AddClient from "../AllClients/AddClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../component/Header";
import SideBar from "../../component/SideBar";
import UniversalLoader from "../../component/Loader/UniversalLoader";
const ClientDetail = () => {
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const {
    data,
    refetch: getClientRefetch,
    isLoading,
    isFetching
  } = useGetClientDetailQuery({ hotelCode: hotelId });
  const [clientData, setClientData] = useState();
  const [showAddClient, setShowAddClient] = useState(false);

  useEffect(() => {
    setClientData(data?.data);
  }, [data]);
  return (
    <>
      
        <UniversalLoader loading={isLoading || isFetching} />
     
      <div className="p-6 w-full h-auto bg-rbg">
        {!showAddClient ? (
          <div className="h-auto  w-full">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-4 ">
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="p-2 cursor-pointer bg-blue-200 rounded-lg"
                >
                  <FaLongArrowAltLeft />
                </div>
                <div>
                  <h1 className="font-bold text-xl">{clientData?.fullName}</h1>
                  <h1 className="font-semibold text-base">Revenue Manager</h1>
                </div>
              </div>
              <Button
                onClick={() => {
                  setShowAddClient(true);
                }}
              >
                Edit Details
              </Button>
            </div>
            {/* <div className="flex mt-8 p-4 rounded-lg gap-4 w-full bg-white">
              <div>Staff Profile</div>
              <div>Hotel Assigned</div>
            </div> */}
            <div className="flex mt-8 p-4 w-full rounded-lg gap-4  bg-white">
              <div className=" flex w-full gap-10 justify-between">
                <div className="bg-red-400 rounded-full w-16 h-16 flex items-center">
                  <img src={ProfilePicture} className="object-fit" alt="" />
                </div>
                <div className="w-full flex justify-between">
                  <div className="ml-20 w-[60%] flex  items-center justify-between">
                    <div className="flex  flex-col justify-between ">
                      <div className="flex gap-2 font-bold">
                        <h1 className="text-neutral-500 ">Property Code: </h1>
                        <p>{clientData?.hotelCode}</p>
                      </div>
                      <div className="flex gap-2 font-bold">
                        <h1 className="text-neutral-500 ">Property Type: </h1>
                        <p>{clientData?.propertyType}</p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between ">
                      <div className="flex gap-2 w-full items-center justify-center font-bold">
                        <h1 className="text-neutral-500 ">Assigned To: </h1>
                        <div className="flex  flex-wrap">
                          {clientData?.assignedTo.map((item) => (
                            <p>{item.fullName},</p>
                          ))}
                        </div>
                      </div>
                      <div className="flex gap-2 font-bold">
                        <h1 className="text-neutral-500 ">Status: </h1>
                        <p>{clientData?.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-8 w-full  justify-between h-full">
              <div className="w-[35%]">
                <UserProfileWrapper label={"Profile Information"}>
                  <div className="flex mt-2 flex-wrap">
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Currency Code
                      </h1>
                      <p className="font-semibold">
                        {clientData?.currencyCode}
                      </p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Property Built Year
                      </h1>
                      <p className="font-semibold">{clientData?.builtYear}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Channel Manager
                      </h1>
                      <p className="font-semibold">
                        {clientData?.channelManager}
                      </p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Total Revenue
                      </h1>
                      <p className="font-semibold">{clientData?.totalRooms}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Star Rating
                      </h1>
                      <p className="font-semibold">{clientData?.starRating}</p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">City</h1>
                      <p className="font-semibold">
                        {clientData?.address?.city}
                      </p>
                    </div>
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">State</h1>
                      <p className="font-semibold flex ">
                        {clientData?.address?.state}
                      </p>
                    </div>{" "}
                    <div className="w-1/2 mt-2">
                      <h1 className="text-slate-500 font-semibold">Country</h1>
                      <p className="font-semibold">
                        {clientData?.address?.country}
                      </p>
                    </div>
                    <div className="w-full mt-2">
                      <h1 className="text-slate-500 font-semibold">
                        Permanent Address
                      </h1>
                      <p className="font-semibold">
                        {clientData?.address?.fullAddress}
                      </p>
                    </div>
                  </div>
                </UserProfileWrapper>
              </div>
              <div className="w-[60%] flex flex-col gap-6">
                <UserProfileWrapper label={"OTA Information"}>
                  <div className="flex h-64 flex-col overflow-y-auto">
                    {clientData?.otaInformation.map((item) => (
                      <div className="w-full flex justify-between mt-2">
                        <div className="w-full flex  justify-between">
                          <h1 className=" font-semibold">{item?.otaName}</h1>
                          <div className="flex gap-2 font-semibold text-slate-500">
                            <p>Linked</p>
                            <Link to={item?.link} className="text-blue-400">
                              Visit
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </UserProfileWrapper>
                <UserProfileWrapper label={"Compset Information"}>
                  <div className="flex mt-2 flex-wrap justify-between">
                    {clientData?.compsetInfo?.map((item) => (
                      <div className="w-[40%] flex   justify-between ml-2">
                        <p>{item.propertyName}</p>
                        {item?.link && (
                          <Link to={item?.link} className="text-blue-400">
                            Link
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                </UserProfileWrapper>
              </div>
            </div>
          </div>
        ) : (
          <AddClient
            getClientRefetch={getClientRefetch}
            data={data}
            setShowAddClient={setShowAddClient}
            editClients={true}
          />
        )}
      </div>
    </>
  );
};

export default ClientDetail;
