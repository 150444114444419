import React from 'react'

const NotFound = () => {
  return (
    <div className='w-[100vw] h-[100vh] flex justify-center items-center'>
        <div className='text-4xl'>
        Opps 404 Not Found 

        </div>
        
        </div>
  )
}

export default NotFound