import React from 'react';
import { formatCurrency } from '../../../utils/FormatCurrency';

const ActivityWrapper = ({ label, value, children }) => {
  const isLabelLong = label.length > 10;
  const displayLabel = isLabelLong ? `${label.slice(0, 10)}...` : label;

  return (
    <div className='w-[100%] border rounded-lg border-slate-500 h-full flex flex-col justify-between'>
      <div className='p-2'>
        <div className='relative group'>
          <h1 className='font-semibold text-sm'>
            {displayLabel}
          </h1>
          {isLabelLong && (
            <div className='absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block p-2 text-xs bg-gray-700 text-white rounded'>
              {label}
            </div>
          )}
        </div>
        {label !== 'Room Nights' ? (
          <div className='flex font-medium text-base items-start gap-2'>
          { formatCurrency(value)}
            {/* <span className='text-xs'>INR</span> */}
          </div>
        ) : (
          <h1 className='flex font-medium text-base items-start gap-2'>{value}</h1>
        )}
      </div>
      {children}
    </div>
  );
};

export default ActivityWrapper;
