import React from "react";
import Skeleton from "../Skeleton";
import Button from "../button";
import UniversalTable from "../Table/UniversalTable";
import {  useGetHighPerformanceClientQuery } from "../../redux/slices/dashboard";
import dataNotFound from "../../assets/icons/404/3.svg";

const TopFiveClients = () => {
  const { data: performanceData, isLoading } = useGetHighPerformanceClientQuery();
  console.log(performanceData, "performance");
  return (
    <>
      {!isLoading ? (
        <div className="w-[100%] rounded-lg overflow-hidden p-4 bg-white h-[100%]">
          <div className="h-full p-2">
              <h1 className="font-semibold">Top 5 performing clients </h1>
              {/* <Button >View All</Button> */}
            <div className="h-[90%] my-2 pb-2 ">
              {performanceData?.data ? (
                <UniversalTable
                  isLoading={isLoading}
                  dataArray={performanceData?.data}
                />
              ) : (
                <div className="w-[99%] flex bg-[#7c8ba130]  items-center justify-start  h-auto p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
                  <img src={dataNotFound} className="h-60 w-60" alt="" />
                  <div className="w-[80%] flex justify-center">
                    <p className="text-xl font-medium">No recent Activities</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default TopFiveClients;
