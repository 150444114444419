import React, { useState } from "react";

const TeamStructure = ({ finalData, setFinalData,selectedTeam,selectedTeamError, setSelectedTeam ,handleTeamStructureSubmit  }) => {


const  handleChange=(event)=>{
  setSelectedTeam(event?.target?.value);
  setFinalData({ ...finalData, companyTeamSize: event?.target?.value });
}

  const handleSubmit = () => {
 
  };

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Team Structure</h1>

        <p className="text-[#96A0AD] my-2">
          Please briefly describe your agency's team structure (e.g.dedicated
          revenue managers, regional teams, centralized marketing)
        </p>
      </div>

      <div>
        <p className="my-2 f">
          Please Select the options that best describe your agency's team size
        </p>

        <label className="flex items-center gap-2 m-2">
          <input
            type="radio"
            id="smallTeam"
            name="teamSize"
            value="small"
            onChange={handleChange}
            checked={selectedTeam === "small"}
          />
          Small Team (1-5 People)
        </label>

        <label className="flex items-center gap-2 m-2">
          <input
            type="radio"
            id="mediumTeam"
            name="teamSize"
            value="medium"
            onChange={handleChange}
            checked={selectedTeam === "medium"}
          />
          Medium Team (6-20 People)
        </label>

        <label className="flex items-center gap-2 m-2">
          <input
            type="radio"
            id="largeTeam"
            name="teamSize"
            value="large"
            onChange={handleChange}
            checked={selectedTeam === "large"}
          />
          Large Team (21-50 People)
        </label>

        <label className="flex items-center gap-2 m-2">
          <input
            type="radio"
            id="enterpriseTeam"
            name="teamSize"
            value="enterprise"
            onChange={handleChange}
            checked={selectedTeam === "enterprise"}
          />
          Enterprise Team (50+ People)
        </label>

        {selectedTeamError && <p className="text-red-500 mt-2">{selectedTeamError}</p>}
      </div>


    </div>
  );
};

export default TeamStructure;
