import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LuUser } from "react-icons/lu";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CountryCodeDropDown from "./countryCodeDropdown";

const ReusableInput = ({
  type = "text",
  placeholder = "Enter a placeholder",
  className,
  id = "enter an id",
  name = "enter a name",
  defaultValue = "",
  rules = {},
  label = "enter a label",
  min,
  max,
  showIcon = false,
  customIcon = null,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [showPassword, setShowPassword] = useState("password");
  const [selectedCode, setSelectedCode] = useState({"country":"India","code":"91","iso":"IN"});
  const getNestedError = (errors, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], errors);
  };
  const error = getNestedError(errors, name);

  return (
    <div className="flex flex-col items-start mb-4">
      <label
        htmlFor={id}
        className="text-lg font-medium text-[#343434] pb-2 pl-0.5"
      >
        {label}
      </label>
      <div className="relative w-full flex items-center">
        {customIcon ? (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {customIcon}
          </div>
        ) : (
          showIcon && (
            <div className="absolute w-auto cursor-pointer left-0 flex items-center">
              {type === "phoneNumber" && <CountryCodeDropDown selectedCode={selectedCode} setSelectedCode={setSelectedCode} />}
               <div className="absolute cursor-pointer inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                {type === "text" && <LuUser />}
                {type === "password" && <RiLockPasswordLine />}
                {type === "email" && <MdEmail />}
              </div> 
            </div>
          )
        )}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <input
              aria-label={label}
              type={type === "password" ? showPassword : type === "phoneNumber" ? "text" : type} // Ensure type is text for phoneNumber
              placeholder={placeholder}
              id={id}
              min={min}
              max={max}
              {...field}
              {...rest}
              className={`font-sans text-sm font-normal w-full border border-solid border-[#d6d9de] box-border rounded-[9px] ${
                showIcon || customIcon
                  ? type === "phoneNumber"
                    ? "pl-16"
                    : "pl-10"
                  : "pl-2"
              } pr-5 h-14 text-[#2a2e34] ${className}`}
            />
          )}
        />
        {type === "password" && (
          <span
            onClick={() =>
              setShowPassword(showPassword === "password" ? "text" : "password")
            }
            className="text-xs flex absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
          >
            {showPassword === "text" ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
          </span>
        )}
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error?.message}</p>}
    </div>
  );
};

export default ReusableInput;