import React, { useEffect, useState } from "react";

import Button from "../../component/button";
import ReusableInput from "../../component/InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import NormalDropDown from "../../component/DropDown/NormalDropDown";
import {
  useAddClientMutation,
  useGetClientDetailQuery,
  useGetDepartmentQuery,
  useGetUserDropDownQuery,
  useUpdateClientMutation,
} from "../../redux/slices/Setting";
import { FaArrowLeft } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const AddClient = ({
  data,
  setShowAddClient,
  editClients = false,
  getClientRefetch,
}) => {
  const methods = useForm();
  const { hotelId } = useParams();
  const [propertyType, setPropertyType] = useState("Single Property");
  const [chainName, setChainName] = useState();
  const [assignedTo, setAssignedTo] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [departmentOption, setDepartmentOptions] = useState();
  const { data: departmentData } = useGetDepartmentQuery();
  useEffect(() => {
    setDepartmentOptions(departmentData?.data || []);
  }, [departmentData?.data]);
  const propertyTypeOption = ["Single Property", "Chain Property"];
  const chainNameOption = ["Revenue Manager"];

  const {data:userDropDown}=useGetUserDropDownQuery();
  const assignedToOption =userDropDown?.data;
  const [updateClient] = useUpdateClientMutation();
  const [addClient] = useAddClientMutation();
  useEffect(() => {
    if (data?.data) {
      methods.setValue("propertyName", data?.data?.propertyName);
      setPropertyType(data?.data?.propertyType);
      methods.setValue("propertyOwnerName", data?.data?.ownerName);
      methods.setValue("propertyOwnerNumber", data?.data?.ownerPhoneNumber);
      methods.setValue("propertyOwnerEmail", data?.data?.ownerEmail);

      setAssignedTo(data?.data?.assignedTo?.map((item) => item?.fullName));
    }
  }, [data?.data]);
  const onSubmit = (data) => {
    if (editClients) {
      updateClient({
        propertyCode: hotelId,
        propertyDetails: {
          propertyName: data?.propertyName,
          assignedTo: assignedTo,
          ownerName: data?.propertyOwnerName,
          ownerEmail: data?.propertyOwnerEmail,
          ownerPhoneNumber: data?.propertyOwnerNumber,
          department: selectedDepartment,
        },
      })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setShowAddClient(false);
          getClientRefetch();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    } else {
      addClient({
        clientData: {
          propertyName: data?.propertyName,
          propertyType: propertyType,
          chainName: chainName,
          assignedTo: assignedTo,
          ownerName: data?.propertyOwnerName,
          ownerEmail: data?.propertyOwnerEmail,
          ownerPhoneNumber: data?.propertyOwnerNumber,
          department: selectedDepartment,
        },
      })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { position: "bottom-right" });
          setShowAddClient(false);
          getClientRefetch();
        })
        .catch((err) => {
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    }
  };
  useEffect(() => {
    setDepartmentOptions(departmentData?.data || []);
  }, [departmentData?.data]);
  return (
    <div className="w-[100%] bg-rbg p-4 h-auto">
      <FormProvider {...methods}>
        <form
          className="flex flex-col w-full justify-start items-between"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className=" w-full flex justify-between items-center ">
            <div className="flex gap-4 items-center">
              <div className="">
                <Button
                  onClick={() => {
                    setShowAddClient(false);
                  }}
                >
                  <FaArrowLeft />
                </Button>
              </div>
              <div className="">
                <h1 className="text-2xl font-bold">
                  {editClients ? "Edit Client" : "Add New Client"}
                </h1>

                {editClients ? (
                  <p className="">
                    Let's edit client of your's and assign to your team
                  </p>
                ) : (
                  <p className="">
                    Let's add new client of your's and assign to your team
                  </p>
                )}
              </div>
            </div>
            <div>
              <Button>Save Property</Button>
            </div>
          </div>
          <div className="w-[25%] mt-6  flex flex-col justify-start ">
            <ReusableInput
              label="Property Name"
              name="propertyName"
              type="text"
              placeholder="Enter Property Name"
              rules={{ required: "Property Name is required" }}
              showIcon={false}
            />
            {!editClients && (
              <div className="my-2 ">
                <h1 className="text-lg font-medium text-[#343434]">
                  Property Type
                </h1>
                <div className="h-12">
                  <NormalDropDown
                    options={propertyTypeOption}
                    label="Select an option"
                    selectedValue={propertyType}
                    setSelectedValue={setPropertyType}
                  />
                </div>
              </div>
            )}
            {propertyType != "Single Property" && (
              <div className="">
                {/* <h1 className="text-lg font-medium text-[#343434]">Chain Name</h1> */}
             
                  <ReusableInput
                    label="Chain Name"
                    name="chainName"
                    type="text"
                    placeholder="Enter Chain Name"
                    rules={{ required: "Property Chain Name is required" }}
                    showIcon={false}
                  />
     
              </div>
            )}
            <div className="">
              <h1 className="text-lg font-medium text-[#343434]">
                Department{" "}
              </h1>
              <div className="h-12">
                <NormalDropDown
                  options={departmentOption}
                  label="Select an option"
                  selectedValue={selectedDepartment}
                  setSelectedValue={setSelectedDepartment}
                  isMulti={true}
                  sendOnlyId={true}
                />
              </div>
            </div>
            <div className="my-2">
              <h1 className="text-lg font-medium text-[#343434]">
                Assigned To
              </h1>
              <div className="h-12">
                <NormalDropDown
                  options={assignedToOption}
                  label="Select an option"
                  selectedValue={assignedTo}
                  setSelectedValue={setAssignedTo}
                  isMulti={true}
                  sendOnlyId={true}
                />
              </div>
            </div>
            <ReusableInput
              label="Property Owner Name"
              name="propertyOwnerName"
              type="text"
              placeholder="Enter Property Owner Name"
              rules={{ required: "Property Owner Name is required" }}
              showIcon={false}
            />
            <ReusableInput
              label="Property Owner"
              name="propertyOwnerNumber"
              type="phoneNumber"
              placeholder="Enter Property Owner number"
              rules={{ required: "Property Owner number is required" }}
              showIcon={true}
            />
            <ReusableInput
              label="Property Owner Email"
              name="propertyOwnerEmail"
              type="email"
              placeholder="Enter Property Owner Email address"
              rules={{ required: "Property Owner Email is required" }}
              showIcon={true}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddClient;
