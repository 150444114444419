import getAccessToken from "../../../utils/getAccessToken";
import getToken from "../../../utils/getToken";
import { emptySplitApi } from "../../injectEndpoint";

export const OnBoarding = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `onboarding/login`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["login"],
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: `onboarding/signup`,
        method: "POST",
        headers: {
          authorization: getAccessToken(),
        },
        body: data,
      }),

      invalidatesTags: ["signup"],
    }),
    onBoarding: builder.mutation({
      query: (data) => ({
        url: `onboarding/getOnboardingInfo`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["onBoarding"],
    }),
    basicDetails: builder.mutation({
      query: (data) => ({
        url: `onboarding/basicDetail`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["basicDetails"],
    }),
    OTPVerfication: builder.mutation({
      query: (data) => ({
        url: `onboarding/otpVerification`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["OTPVerfication"],
    }),
    ResendOTP: builder.mutation({
      query: (data) => ({
        url: `onboarding/resendOTP`,
        method: "POST",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["ResendOTP"],
    }),
    loginInfo: builder.query({
      query: () => ({
        url: `onboarding/getLoginInfo`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["loginInfo"],
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `onboarding/forgotPassword`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["forgotPassword"],
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `onboarding/resetPassword`,
        method: "PATCH",
        headers: {
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["resetPassword"],
    }),
    getAllCategory: builder.query({
      query: () => ({
        url: `onboarding/getAllCategory`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getAllCategory"],
    }),
    onBoardingInviteMember: builder.mutation({
      query: (data) => ({
        url: `notification/invitePeople`,
        method: "POST",
        headers:{
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["onBoardingInviteMember"],
    }),
    getScenarioDetails: builder.query({
      query: (data) => ({
        url: "onboarding/getScenarioDetails",
        method:'GET',
        params: data,
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getScenarioDetails"],
    }),
    addScenarioByUser:builder.mutation({
      query: (data) => ({
        url: `onboarding/addScenarioByUser`,
        method: "POST",
        headers:{
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["addScenarioByUser"],
    }),
    updateFrequency:builder.mutation({
      query: (data) => ({
        url: `onboarding/updateFrequency`,
        method: "POST",
        headers:{
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["updateFrequency"],
    }),
    getScenarioFrequency: builder.query({
      query: (data) => ({
        url: "onboarding/getScenarioFrequency",
        method:'GET',
        params: data,
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["getScenarioFrequency"],
    }),
    switchToRatex: builder.query({
      query: () => ({
        url: `onboarding/switchToRatex`,
        method: "GET",
        headers: {
          Authorization: getToken(),
        },
      }),
      invalidatesTags: ["switchToRatex"],
    }),

    goBack:builder.mutation({
      query: (data) => ({
        url: `onboarding/updateCurrentScreen`,
        method: "PATCH",
        headers:{
          Authorization: getToken(),
        },
        body: data,
      }),
      invalidatesTags: ["updateCurrentScreen"],
    }),
    overrideExisting: false,
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useOnBoardingMutation,
  useBasicDetailsMutation,
  useOTPVerficationMutation,
  useResendOTPMutation,
  useLoginInfoQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetAllCategoryQuery,
  useOnBoardingInviteMemberMutation,
  useGetScenarioDetailsQuery,
  useAddScenarioByUserMutation,
  useUpdateFrequencyMutation,
  useGetScenarioFrequencyQuery,
  useSwitchToRatexQuery,
  useGoBackMutation,
} = OnBoarding;
