import React, { useEffect, useState } from "react";
import { HiOutlineHome } from "react-icons/hi2";
import { LuInbox } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegChartBar } from "react-icons/fa";
import { IoIosSnow } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoMdPeople } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaQrcode } from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const SideBar = ({ setSelectedItem=()=>{}, selectedItem }) => {
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate()
  const handleLogout = () => {
   
 
        Cookies.remove('token');
    
  
    // Redirect to the login page
    window.location.href = '/login';
  
    // Reload the webpage
    window.location.reload();
  };
  return (
    <div className="w-60 pl-2  flex flex-col border-r-2 bg-white gap-2 justify-between sticky  h-[93vh]">
      <div>
        <div className="border-t-2 border-b-2">
          <div onClick={()=>{setSelectedItem('dashboard');  navigate('/dashboard'); }} className="m-2 flex rounded-md  p-2 cursor-pointer hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2">
            <HiOutlineHome size={20} />

            <div>Dashboard</div>
          </div>
        </div>

        <div className=" border-b-2">
          <div onClick={()=>{setSelectedItem('All client'); navigate('/All-client'); }}  className="flex rounded-md m-2 p-2 cursor-pointer  hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2 ">
            <IoPeopleOutline size={20} />

            <div>All client</div>
          </div>
          <div onClick={()=>{setSelectedItem('Employees'); navigate('/employee') }}  className="flex rounded-md m-2 p-2 cursor-pointer  hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2 ">
            <IoPeopleOutline size={20} />

            <div>Employees</div>
          </div>
          {/* <div
            className={`flex  m-2 p-2 cursor-pointer  hover:bg-opacity-25 justify-start items-center gap-2 `}
          >
            <div className="flex  flex-col items-center  ">
              <div
                onClick={() =>
                  expand == "professionalService"
                    ? setExpand("")
                    : setExpand("professionalService")
                }
                className="flex  gap-2 items-center"
              >
                {expand == "professionalService" ? (
                  <IoMdPeople size={25} />
                ) : (
                  <IoMdPeople size={25} />
                )}

                <div>Employees</div>
                {expand == "professionalService" ? (
                  <RiArrowDropUpLine size={25} />
                ) : (
                  <RiArrowDropDownLine size={25} />
                )}
              </div>
              <div className="flex ml-4  items-start flex-col justify-center w-full">
                {expand == "professionalService" && (
                  <div className="flex rounded-md  w-full cursor-pointer  justify-start items-center">
                    <div className="flex flex-col justify-center items-center">
                      <div className="w-1 h-4 rounded-t-md bg-white bg-opacity-45"></div>

                      <div
                        className={`w-2 h-2 rounded-full ${selectedItem == "salesTeam" ? "bg-green-400" : "bg-white"} `}
                      ></div>
                      <div className="w-1 h-6  bg-white bg-opacity-45"></div>
                    </div>
                    <div
                      className="ml-3 hover:bg-customColor w-full hover:bg-opacity-25 rounded-md p-3 "
                      onClick={() => {
                        setSelectedItem("salesTeam");
                      }}
                    >
                      Sales Team{" "}
                    </div>
                  </div>
                )}

                {expand == "professionalService" && (
                  <div className="flex rounded-md  w-full cursor-pointer  justify-start items-center ">
                    <div className="flex flex-col justify-center items-center">
                      <div className="w-1 h-6  bg-white bg-opacity-45"></div>

                      <div
                        className={`w-2 h-2 rounded-full ${selectedItem == "lead" ? "bg-green-400" : "bg-white"} `}
                      ></div>

                      <div className="w-1 h-6 rounded-b-md bg-white bg-opacity-45"></div>
                    </div>

                    <div
                      className=" ml-3 hover:bg-customColor w-full hover:bg-opacity-25 rounded-md p-3"
                      onClick={() => {
                        setSelectedItem("lead");
                      }}
                    >
                      Lead{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="border-b-2">
          <div onClick={()=>{setSelectedItem('setting')}} className="  flex rounded-md m-2 p-2  cursor-pointer hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2">
            <IoSettingsOutline size={20} />

            <div>Setting</div>
          </div>
        </div> */}
      </div>
      <div className="border-t-2">
        <div onClick={()=>{setSelectedItem('support'); navigate('Help-And-Support')}} className="  flex rounded-md p-3  cursor-pointer hover:bg-customColor hover:bg-opacity-25 justify-start items-center mx-2 gap-2">
          <IoIosInformationCircleOutline size={20} />

          <div >Help & Support</div>
        </div>

        <div  onClick={()=>{setSelectedItem('FAQs'); navigate('faq')}} className="mx-2  flex rounded-md p-3  cursor-pointer hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2">
          <FaQrcode size={20} />

          <div>FAQs</div>
        </div>
        <div onClick={handleLogout} className="  flex rounded-md p-3 mx-2 cursor-pointer hover:bg-customColor hover:bg-opacity-25 justify-start items-center gap-2">
          <CiLogout size={20} />

          <div>Logout</div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
