import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import NotFound from "../component/notFound";
import OTPInputField from "../component/OTPvarification/OTPInputField";
import LoginPage from "../pages/OnBoarding/LoginPage";
import NewOnBoarding from "../pages/OnBoarding/newOnBoarding";
import LoadingPage from "../pages/OnBoarding/LoadingPage";
import LoginDashboard from "../pages/OnBoarding/LoginDashboard";
import DividedScreen from "../component/dividedScreen";
import AddTeam from "../pages/addTeam";
import LastStep from "../pages/OnBoarding/lastStep";
import NewLoginDashboard from "../pages/OnBoarding/NewLoginDashboard";
import getToken from "../utils/getToken";
import UserDetail from "../pages/UserDetail/UserDetail";
import ClientDetail from "../pages/ClientDetail/clientDetails";
import AllClient from "../pages/AllClients/AllClient";
import AllEmployees from "../pages/employees/AllEmployees";
import Support from "../pages/Support/Support";
import FAQs from "../pages/Support/FAQs";
import KanabelContainer from "../component/Kanabel/KanabelContainer";
import Test from "../pages/newPages/test";
function AppRouters() {
  const location = useLocation();
  const isAuthenticated = getToken() ? true : false;
const navigate =useNavigate();

  return (
    <Routes key={location.pathname} location={location}>
      <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={
        isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />
      } />
        <Route path="/onboarding" element={<NewOnBoarding />} />
        <Route path="/loginDashboard" element={<NewLoginDashboard />} />
        <Route path="/add-team-members" element={<AddTeam />} />
        <Route path="/onboarding/success" element={<LastStep />} />
        <Route path="/otp" element={<OTPInputField />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/user/:userId" element={<UserDetail />} />
        <Route path="/client/:hotelId" element={<ClientDetail />} />
        <Route path="/All-client" element={<AllClient />} />
        <Route path="/employee" element={<AllEmployees />} />
        <Route path="/Help-And-Support" element={<Support />} />
        <Route path="/FAQ" element={<FAQs />} />
        <Route path="/test" element={<Test />} />
        <Route path="/Kanabel" element={<KanabelContainer />} />








      </Route>
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<LoginPage />} />


        <Route path="/forgot-password" element={<LoginDashboard />} />
        <Route path="/change-password/*" element={<LoginDashboard />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouters;
