import React, { useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import NormalDropDown from "../component/DropDown/NormalDropDown";
import Button from "../component/button";
import { useGetDesignationQuery } from "../redux/slices/dashboard";

const Row = ({
  email,
  departmentOption,
  selectedValue,
  handleDepartmentChange,
  handleDesignationChange,
  selectedDesignation,
  handleDelete,
  setIsLoading,
  index,
}) => {
  //   const [selectedDesignation, setSelectedDesignation] = useState();
  const { data: designationOptions,isLoading,isFetching } = useGetDesignationQuery({
    departmentId: selectedValue,
    
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading,isFetching]);
  return (
    <div className="w-[100%] flex justify-between">
      <div className="flex items-center gap-2">
        <div>
          <CgProfile size={24} />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <p className="text-sm font-semibold mr-3">Email:</p>
            <div className="text-sm font-medium">{email}</div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 w-auto items-center justify-end">
        <div className="flex w-full items-center justify-end gap-2">
          <NormalDropDown
            options={departmentOption}
            label="Select an option"
            selectedValue={selectedValue || "select an option"}
            setSelectedValue={(value) => handleDepartmentChange(index, value)}
            sendOnlyId={true}
          />
         {designationOptions?.data.length > 0 && (  <div className="w-56">
           
              <NormalDropDown
                options={designationOptions?.data}
                label="Select an option"
                selectedValue={selectedDesignation || "select an option"}
                setSelectedValue={(value) =>
                  handleDesignationChange(index, value)
                }
                sendOnlyId={true}
              />
          </div>
            )}
        </div>
        <Button onClick={() => handleDelete(index)}>Remove</Button>
      </div>
    </div>
  );
};

export default Row;
