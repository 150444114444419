import React, { useEffect, useRef, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { BsRecord2 } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const ReusableDropDown = ({
  label,
  selected,
  setSelected,
  options,
  subOptions,
  isMultiSelect = false,
}) => {
  const [show, setShow] = useState(null);
  const [email, setEmail] = useState();
  const methods = useForm();
  const dropdownRef = useRef(null);

  const subOptionRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const [isOpen, setIsOpen] = useState(false);
  const [isSubOpen, setIsSubOpen] = useState(false);
  const handleOptionClick = (option) => {
    if (isMultiSelect) {
      if (selected.includes(option.email)) {
        const filteredData = selected.filter(
          (item) => item?.email != option?.email
        );
        setSelected(filteredData);
        return;
      } else {
        setSelected([...selected, option]);
        const filterOption = filteredOptions.filter(
          (item) => item?.email != option?.email
        );
        setFilteredOptions(filterOption);
        return;
      }
    }
    // setSelected(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const [selectedSubOptions, setSelectedSubOptions] = useState("Can Edit");

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const handleRemove = (option) => {
    const filteredData = selected.filter((item) => item != option);
    setSelected(filteredData);

    setFilteredOptions([...filteredOptions, option]);
  };
  const handleSubmitForm = (email) => {
    setSelected([...selected, { email: email }]);
    setEmail("");
  };
  console.log(selected, "sleected");
  return (
    <div className="flex text-xs font-medium text-[#343434] w-full h-full flex-col gap-2 ">
      {label}
      <div
        ref={dropdownRef}
        className="relative font-sans text-sm font-normal w-full border border-solid border-[#d6d9de] box-border rounded-[9px] p-2 h-auto text-[#2a2e34]"
      >
        <div className="w-full flex text-sm items-center  text-gray-500 focus:outline-none cursor-pointer">
          <div className="flex items-center w-full justify-between">
            <div
              className="flex gap-2 items-center  flex-wrap w-[100%]"
              onClick={() => {
                setIsOpen(!isOpen);
                setIsSubOpen(false);
              }}
            >
              {!isMultiSelect
                ? selected
                : selected.map((item) => (
                    <div className=" flex p-2 border rounded-xl">
                      <div
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setIsSubOpen(false);
                        }}
                        className="flex gap-2 items-center justify-center"
                      >
                        {item?.email}
                        <div
                          onClick={() => handleRemove(item)}
                          className="cursor-pointer"
                        >
                          <IoMdClose />
                        </div>
                      </div>
                    </div>
                  ))}

<input
                type="text"
                value={email}
                placeholder="Enter email"
                className={"p-2 h-fit rounded-md border-none outline-none"}
                style={{ width: `${(email?.length ? email?.length : 7) + 5}ch` }} // Adjust width based on input length
                showIcon={false}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if(emailRegex.test(e.target.value)){
                      e.preventDefault();
                      handleSubmitForm(e.target.value);
                    }else{
                      toast.error("Enter valid email!",{position:'bottom-right'})
                    }
                   
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            {isMultiSelect && (
              <div className="flex items-center">
                {subOptions?.length > 0 && (
                  <div className="relative">
                    <div
                      onClick={() => {
                        setIsSubOpen(!isSubOpen);
                        setIsOpen(false);
                      }}
                    >
                      {selectedSubOptions}
                    </div>
                    {isSubOpen && (
                      <div
                        ref={subOptionRef}
                        className="absolute w-max right-0 top-8 bg-white"
                      >
                        {subOptions?.map((item) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedSubOptions(item);
                                setIsSubOpen(false);
                              }}
                              className=" px-4 py-2 cursor-pointer"
                            >
                              {item}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}{" "}
                {(isOpen || isSubOpen) ? (
                  <RiArrowDropUpLine size={20} />
                ) : (
                  <RiArrowDropDownLine size={20} />
                )}
              </div>
            )}
          </div>
        </div>
        {isOpen && filteredOptions.length > 0 && (
          <div
            className={`absolute rounded-t-none ${subOptions?.length > 0 ? "w-full" : "w-full"}  flex z-20 flex-col left-0 right-0 bg-white border border-gray-200 rounded mt-3`}
          >
            {filteredOptions.map((item, val) => (
              <div
                className="px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 items-center  flex gap-2  cursor-pointer"
                onClick={() => handleOptionClick(item)}
              >
                <div className="h-6 w-6 rounded-full border"></div>
                {item?.email}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReusableDropDown;
