import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function findPostalCode(addressComponents) {
  for (const component of addressComponents) {
    if (component.types.includes("postal_code")) {
      return component.long_name;
    }
  }
  return null;
}

function AutoSearch({
  setAddress,
  address,
  name,
  rules,
  icon,
  className,
  onBlur,
  rest,
  control,
  field,
  setValue,
  placeholder,
  id,
  label,
  setLog,
  setLat,
  type,
  setCity,
  setState,
  state,
  setCountry,
  country,
  setPostalCode,
  postalCode,
}) {


  function removeLastThreeWords(inputString) {
    const words = inputString.split(",");
    const resultString = words.slice(0, -3).join(",");
    return resultString;
  }

  const handleSelect = async (selected) => {
    setAddress(selected);
    const results = await geocodeByAddress(selected);
    const latLng = await getLatLng(results[0]);
    const result = results[0];
    const addressComponents = result.address_components;
    const postalCodeComponents = result.address_components;

    setLat(latLng.lat);
    setLog(latLng.lng);
    // Extract country, city, and state from the address components
    let extractedCity, extractedState, extractedCountry, extractedPostalCode;
    for (const component of addressComponents) {
      if (component.types.includes("locality")) {
        extractedCity = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        extractedState = component.long_name;
      } else if (component.types.includes("country")) {
        extractedCountry = component.long_name;
      } else if (postalCodeComponents) {
        setPostalCode(findPostalCode(postalCodeComponents));
      }
    }
    setCity(extractedCity);
    setState(extractedState || "");
    setCountry(extractedCountry || "");
    setPostalCode(extractedPostalCode || "");

    setValue(name, removeLastThreeWords(selected));

    if (
      (selected && selected.split(",").length >= 4) ||
      selected.split(",").length >= 3
    ) {
      const filteredAddress = selected.split(",").slice(-3);
      setValue("state", filteredAddress[1]);
      setValue("country", filteredAddress[2]);
      setValue("city", filteredAddress[0]);
      setValue("pinCode", postalCode);
    }

    // setValue(name, name === "city" ? extractedCity : selected);
  };
  const apiKey = "AIzaSyBRAnfSXzM-fQXpa751GkbMQDEuavUSDP0";

  return (
    <PlacesAutocomplete
    value={address}
    onChange={setAddress}
    onSelect={handleSelect}
    {...field}
    {...rest}
    options={{
      apiKey: apiKey,
    }}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div className="relative ">
        <input
          {...getInputProps({
            // placeholder: {placeholder},
          })}
          name={name}
          className={`peer ${className}`} // Add `peer` class here
          onBlur={onBlur}
          rules={rules}
          id={id} // Ensure this id matches the label's htmlFor
          control={control}
          placeholder={placeholder}
        />
        <label
          htmlFor={id} // Ensure this matches the input's id
          className={`absolute text-sm text-black px-4 peer-focus:w-fit h-10 flex items-center peer-focus:h-auto text-start dark:text-gray-400 
            duration-300 transform -translate-y-4 top-3 origin-[0] bg-white dark:bg-white peer-focus:px-1  
            peer-focus:text-black peer-focus:dark:text-blue-700 peer-placeholder-shown:scale-100 
            peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 
            peer-focus:scale-75 peer-focus:-translate-y-4 peer-[:not(:placeholder-shown)]:w-fit 
            peer-[:not(:placeholder-shown)]:px-2 peer-[:not(:placeholder-shown)]:h-auto cursor-pointer`} // Add cursor-pointer class
        >
          {label}
        </label>

        <div className="z-10 absolute bg-white w-[100%]">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion) => {
            // Filter suggestions to only show cities
            if (suggestion.types) {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              return (
                <div className="z-10"
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style: { maxWidth: "324px" },
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
  );
}

export default AutoSearch;
