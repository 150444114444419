import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRouters from "./routes/appRouters";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Header from "./component/Header";
import SideBar from "./component/SideBar";
import { useState } from "react";

function AppContent() {
  const location = useLocation();
  const noHeaderAndSidebarPaths = ['/onboarding','/OnBoarding','/login','/signup','/forgot-password','/add-team-members','/onboarding/success','/otp','/loading','/change-password/','/loginDashboard','/onBoarding'];
  const [sideBarOpen, setSideBarOpen] = useState();
  const shouldShowHeaderAndSidebar = !noHeaderAndSidebarPaths.includes(location.pathname);

  return (
    <>
      {shouldShowHeaderAndSidebar && <Header sideBarOpen={sideBarOpen}  setSideBarOpen={setSideBarOpen} />}
      <div className="flex">
        {(shouldShowHeaderAndSidebar && sideBarOpen) && <SideBar />}
        <AppRouters />
      </div>
    </>
  );
}

function App() {
  return (
    <div className="dark themeToggle ">
    <Router>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <AppContent />
      </GoogleOAuthProvider>
    </Router>
    </div>
  );
}

export default App;