import React from "react";
import Bubbles from "../assets/icons/Circles/Bubble.svg";
import SemiBubble from "../assets/icons/Circles/semiBubble.svg";
import UpSideDown from "../assets/icons/Circles/UpSideDown.svg";

const CustomWrapper = ({ children, isCenter, isBubble }) => {
  return (
    <div className="h-[100vh] relative w-[100%]">
      <div className="w-[100%] absolute top-0 bg-RGreen  h-[50vh] ">
        {" "}
        {isBubble && (
          <div>
            <div className="absolute left-0 top-0">
              <img src={Bubbles} className="w-[100%] h-[100%]" />
            </div>
            <div className="absolute right-0 bottom-0">
              <img src={SemiBubble} className="w-[100%] h-[100%]" />
            </div>
            <div className="absolute top-0 left-[50%]">
              <img src={UpSideDown} className="w-[100%] h-[100%]" />
            </div>
            <div className="flex mx-10 py-10 md:gap-6 flex-col h-[100%] w-[100%] md:w-1/3">
              <div className="flex flex-col text-white"></div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`bg-transparent flex ${isCenter ? "justify-center items-center" : ""}  w-[100%] absolute h-screen top-0`}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomWrapper;
