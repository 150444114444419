import RevensCalling from "../../assets/icons/Revens/smallRevensCalling.svg";
import React from 'react'

const RevensMessage = ({message}) => {
  return (
    <div className="flex gap-3">

    <div className="w-[50px] flex flex-col justify-start items-start z-20">
    <img src={RevensCalling} className="object-cover z-20" alt="" />
  </div>
    <div className="revens flex flex-col justify-start w-[100%] h-auto overflow-hidden">
          
      <h1 className="text-semibold">Revens</h1>
      <h1 className="w-[100%] text-sm flex flex-wrap" style={{ overflowWrap: 'break-word' }}>
        {message}
      </h1>
    </div>
    </div>
  )
}

export default RevensMessage