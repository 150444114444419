import React, { useEffect, useRef, useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import '../../../utils/hideScroll.css'
function AdvanceTable({setShowAddUser}) {
  const [show, setShow] = useState(null);
  const [selected, setSelected] = useState();
  const dropdownRef = useRef(null);
  const dropdownToggleRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownToggleRef.current &&
        !dropdownToggleRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionClick = (option) => {
    setSelected(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      <div className="sm:px-6 w-full">
        <div className="px-4 md:px-10 py-4 md:py-7">
          <div className="lg:flex items-center justify-between">
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
              Users
            </p>
            <div className="md:flex items-center mt-6 lg:mt-0">
              <div className="flex items-center">
                <div className="flex items-center pl-3 bg-white border w-64 rounded border-gray-200">
                  <IoMdSearch />

                  <input
                    type="text"
                    className="py-2.5 pl-1 w-full focus:outline-none text-sm rounded text-gray-600 placeholder-gray-500"
                    placeholder="Search"
                  />
                </div>
              </div>
              <div
                className="flex h-full items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 "
              >
                <div
                  ref={dropdownRef}
                  className="relative w-40 py-1 px-3 bg-white lg:ml-3 border rounded border-gray-200"
                >
                  <div
                    className="w-full flex text-sm leading-3 text-gray-500 focus:outline-none cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                   <div className="flex items-center w-full justify-between"> <h1> {selected || "Sort By"}</h1> <span> {isOpen?<RiArrowDropUpLine  size={30}/> : <RiArrowDropDownLine size={30} />} </span></div>
                  </div>
                  {isOpen && (
                    <div className="absolute flex z-20 flex-col left-0 right-0 bg-white border border-gray-200 rounded mt-3">
                      <div
                        className="px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick("Shortlisted")}
                      >
                       Current Employee
                      </div>
                      <div
                        className="px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleOptionClick("Rejected")}
                      >
                        Left Employee
                      </div>
                      <div
                        onClick={() => {
                          setSelected("");
                        }}
                        className="px-2 flex justify-end cursor-pointer"
                      >
                        {" "}
                        clear
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div onClick={()=>{setShowAddUser(true)}} className="flex ml-2 items-center">
                <div className="flex items-center p-2 bg-blue-700  text-white cursor-pointer border w-fit  rounded border-gray-200">
               
 
             Add user
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white h-[500px] overflow-y-scroll px-4 hideScroll rounded-lg md:px-8 xl:px-10 overflow-x-auto">
  <table className="w-full overflow-y-scroll whitespace-nowrap">
    <thead>
      <tr className="h-20 sticky bg-white top-0 w-full z-10 text-sm leading-none text-gray-600">
        <th className="font-normal text-left">Name</th>
        <th className="font-normal text-left pl-10">Revenue Growth</th>
        <th className="font-normal text-left pl-10">Occupancy</th>
        <th className="font-normal text-left pl-10">ADR</th>
        <th className="font-normal text-left w-32">Action</th>
      </tr>
    </thead>
    <tbody className="w-[100%] ">
      {[...Array(10)].map((_, index) => (
        <tr key={index} className="h-20 text-sm leading-none text-gray-700 border-b border-t border-gray-200 bg-white hover:bg-gray-100">
          <td>
            <div className="flex flex-col items-start">
              <p className="font-bold">Shapura Haveli</p>
              <p className="text-xs">Chain Property</p>
            </div>
          </td>
          <td >
            <div className="flex gap-2">

            <p className="pl-10">₹ 24,29,549</p>
            <p className="text-green-500">↑ 187%</p>
            </div>
          </td>
          <td >
            <div className="flex gap-2">

            <p className="pl-10">24%</p>
            <p className="text-green-500">↑ 13%</p>
            </div>
          </td>
          <td className="flex items-center gap-2 h-20">
            <p className="pl-10">₹ 24,29,549</p>
            <p className="text-green-500">↑ 197%</p>
          </td>
          <td>
            <button className="text-[#77A980] hover:underline">Unassign</button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


      </div>
    </>
  );
}

export default AdvanceTable;
