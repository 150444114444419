import React, { useEffect, useState } from "react";
import CustomWrapper from "../component/dividedScreen";
import { MdClose } from "react-icons/md";
import Button from "../component/button";
import { FiSend } from "react-icons/fi";
import ReusableDropDown from "../component/DropDown/DropDown";
import { CgProfile } from "react-icons/cg";
import { BsPlus } from "react-icons/bs";
import { useOnBoardingInviteMemberMutation } from "../redux/slices/onBoarding";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NormalDropDown from "../component/DropDown/NormalDropDown";
import { useGetDepartmentQuery } from "../redux/slices/Setting";
import NormalDropdownDupli from "../component/DropDown/NormalDropdownDupli";
import { useIsIpad } from "../utils/isIpad";
import UniversalLoader from "../component/Loader/UniversalLoader";
import Row from "./Row";

const AddTeam = () => {
  const [teamMember, setTeamMember] = useState([]);
  const [sampleArray, setSampleArray] = useState([]);
  const [triggerEnter, setTriggerEnter] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [departmentOption, setDepartmentOptions] = useState([]);
  const { data: departmentData } = useGetDepartmentQuery();
  const isIpad = useIsIpad();
  console.log(departmentData, "depppte");
  useEffect(() => {
    console.log(departmentData?.data, "deppt");
    setDepartmentOptions(departmentData?.data || []);
  }, [departmentData]);
  console.log(departmentOption, "departmentData");
  const [onBoardingInviteMember] = useOnBoardingInviteMemberMutation();
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();
  // teamMember
  // const sampleArray = [
  //   {
  //     name: "saumitra Shukla",
  //     designation: "",
  //     department: "",
  //     email:"saumitra@gmail.com"
  //   },
  //   {
  //     name: "Aman Sharma",
  //     designation: "",
  //     department: "",
  //     email:"aman@gmail.com"

  //   },
  //   {
  //     name: "Akshay Chouhan",
  //     designation: "",
  //     department: "",
  //     email:"akshay@gmail.com"

  //   }
  // ];

  const handleAdd = () => {
    const newTeam = teamMember
      ?.filter((item) => {
        const isSame = sampleArray?.some((itm) => itm?.email === item?.email);
        if (isSame) {
          toast.error("Enter different email!", {
            position: "bottom-right",
          });
          return false;
        }
        return true;
      })
      .map((item) => ({
        designation: null,
        email: item?.email,
      }));
    setSampleArray((prevArray) => [...prevArray, ...newTeam]);
  };

  const handleInvite = () => {
    setIsLoading(true);
    const isValid = sampleArray.every(
      (member) => member.department && member.email
    );

    if (teamMember?.length > 0 && isValid) {
      onBoardingInviteMember({ people: sampleArray })
        .unwrap()
        .then((res) => {
          setIsLoading(false);
          toast.success(res?.message, { position: "bottom-right" });
          navigate("/onboarding/success");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.data?.message, { position: "bottom-right" });
        });
    } else {
      setIsLoading(false);
      toast.error("Add Desgination and email", { position: "bottom-right" });
    }
  };

  const handleDelete = (index) => {
    const newMembers = sampleArray?.filter((item, indexx) => {
      return index !== indexx;
    });
    setSampleArray(newMembers);
    setTeamMember(newMembers);
  };

  const [testSampleArray, setTestSampleArray] = useState([]);
  console.log(sampleArray, "setTestSampleArray");
  useEffect(() => {
    console.log(testSampleArray, "asdf");
    setSampleArray(testSampleArray);
  }, [testSampleArray]);

  const handleDepartmentChange = (index, value) => {
    setSampleArray((prevArray) =>
      prevArray.map((member, i) =>
        i === index ? { ...member, department: value } : member
      )
    );
  };
  const handleDesignationChange = (index, value) => {
    setSampleArray((prevArray) =>
      prevArray.map((member, i) =>
        i === index ? { ...member, designation: value } : member
      )
    );
  }
  return (
    <>
      <UniversalLoader loading={isLoading} />
      <CustomWrapper isCenter isBubble>
        <div className="w-[60%]    ">
          <Modal isTitle={true} title={"Invite Team Members"}>
            <div className="bg-white p-4">
              <div className="text-sm mb-4">Invite by email</div>
            </div>
            <div
              className={`${isIpad ? "h-[300px]" : "h-[500px]"} bg-white overflow-y-auto px-4`}
            >
              <div className="flex items-center gap-4">
                <div className="w-[80%]">
                  <ReusableDropDown
                    selected={teamMember}
                    isMultiSelect={true}
                    setSelected={setTeamMember}
                    options={members}
                    // subOptions={["can edit", "can view"]}
                  />
                </div>
                <div className="w-[20%]">
                  <Button onClick={handleAdd}>
                    <div className="flex justify-between gap-2 items-center w-[100%]">
                      <BsPlus size={20} />
                      Add Email
                    </div>
                  </Button>
                </div>
              </div>
              <div className="bg-white mt-5 py-4 rounded-lg ">
                <div className="text-sm mb-4">Members List:</div>
                <div className="flex flex-col gap-4  min-h-min ">
                  {sampleArray.length > 0
                    ? sampleArray?.map((member, index) => {
                        return (
                          <Row
                            email={member?.email}
                            departmentOption={departmentOption}
                            selectedValue={sampleArray[index]?.department}
                            handleDepartmentChange={handleDepartmentChange}
                            handleDesignationChange={handleDesignationChange}
                            selectedDesignation={sampleArray[index]?.designation}
                            handleDelete={handleDelete}
                            index={index}
                            setIsLoading={setIsLoading}
                          />
                        );
                      })
                    : "Members not found!"}
                </div>
              </div>
            </div>
            <div className="w-[100%] flex justify-end">
              <div className="w-[20%] mt-5">
                <Button onClick={handleInvite}>
                  <div className="flex justify-between gap-2 items-center w-[100%]">
                    Send Invite <FiSend />
                  </div>
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </CustomWrapper>
    </>
  );
};

export default AddTeam;

export const Modal = ({
  children,
  isTitle,
  title,
  onClose = {},
  isWhiteBackground = false,
}) => {
  return (
    <div
      className="h-auto w-[100%] bg-white rounded-lg drop-shadow-md "
      style={{ borderRadius: "10px" }}
    >
      {isTitle && (
        <div className="p-4 w-[100%] justify-between items-center flex">
          <div className="text-lg font-semibold">{title}</div>
          <div>
            <div className="bg-gray-100 cursor-pointer p-1 rounded-full">
              {/* <MdClose /> */}
            </div>
          </div>
        </div>
      )}
      <div
        className={`${isWhiteBackground ? "bg-white" : "bg-[#F8F8F8]"} p-8 w-[100%] mx-auto rounded-lg`}
      >
        <div className="w-[100%]">{children}</div>
      </div>
    </div>
  );
};
