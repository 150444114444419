import React, { useEffect } from "react";
import ActivityManager from "../component/ApexGraphComponent/ActivityManager/ActivityManager";
import ChannelPerformance from "../component/ApexGraphComponent/ChannelPerformance";
import SingleChainProperty from "../component/ApexGraphComponent/SingleChainProperty";
import TopFiveClients from "../component/ApexGraphComponent/TopFiveClients";
import CriticalClients from "../component/ApexGraphComponent/CriticalClients";
import RevenueGrowth from "../component/ApexGraphComponent/RevenueGrowth";
import ClientByStarCategory from "../component/ApexGraphComponent/ClientByStarCategory";
import OnboardingFunnel from "../component/ApexGraphComponent/OnboardingFunnel";
import { useIsIpad } from "../utils/isIpad";
import { useNavigate } from "react-router-dom";
import { useLoginInfoQuery } from "../redux/slices/onBoarding";
import CRODashboard from "./Dashboard/CRODashboard";
import RevenueManager from "./Dashboard/RevenueManager";
import UniversalLoader from "../component/Loader/UniversalLoader";

const MainDashboard = () => {
  const {
    data: currentScreenData,
    isLoading,
    isFetching,
  } = useLoginInfoQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isFetching && currentScreenData?.data) {
      const currentScreen = currentScreenData?.data?.currentScreen;

      if (currentScreen && currentScreen !== 20) {
        navigate("/onBoarding");
      } 
    }
  }, [currentScreenData?.data, isFetching, isLoading]);

  return (
    <div className="w-[100%] min-h-[93vh]  h-auto overflow-y-auto bg-rbg">
      <UniversalLoader loading={isLoading}/>
      {currentScreenData?.data?.designationId === 96876025 ? (
        <CRODashboard />
      ) : currentScreenData?.data?.designationId === 19687170 ? (
        <div className="flex  justify-center min-h-[93vh]">

        <RevenueManager />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MainDashboard;
