import React from "react";
import Skeleton from "../Skeleton";
import Chart from 'react-apexcharts';
import Button from "../button";
import { useGetrevenueGrowthQuery } from "../../redux/slices/dashboard";
import dataNotFound from '../../assets/icons/404/5.svg'

const RevenueGrowth = () => {
    // const isLoading=false

    const {data:revenueGrowthData,isLoading}=useGetrevenueGrowthQuery();

  
    const options = {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 2,
        curve: 'straight'
      },
      xaxis: {
        categories: revenueGrowthData?.data?.date,
        labels: {
          style: {
            colors: '#94A3B8', // Tailwind color slate-400
          },
         
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#94A3B8', // Tailwind color slate-400
          },
          formatter: function (value) {
            return '₹' + value;
          }
        }
      },
      grid: {
        borderColor: '#E2E8F0', // Tailwind color slate-200
      },
      dataLabels: {
        enabled: false
      }
    };
  
    const series = [{
      name: 'Value',
      data:  revenueGrowthData?.data?.value
    }];
  return (
    <>
   { !isLoading ? <div className="w-[100%] rounded-lg p-4 bg-white h-[100%]">
      <div className="w-full flex  p-2 items-center justify-between gap-2 ">
        <h1 className="font-semibold">Revenue Growth </h1>
        {/* <Button >Today</Button> */}
      </div>
  { revenueGrowthData?.data ?  <Chart
        options={options}
        series={series}
        type="line"
        height={350}
      />:<div className="w-full flex bg-[#7c8ba130]  items-center justify-start  h-[90%]  p-4  border-2 border-[#7C8BA1] rounded-lg m-2 ">
      
      <img src={dataNotFound} className="h-60 w-60" alt="" />
      <div className="w-full flex justify-center">

      <p className="text-xl font-medium">No recent Activities</p>
      </div>
       </div>}
    </div>: <Skeleton/>}
    </>

  );
};

export default RevenueGrowth;
