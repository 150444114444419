import React, { useState, useEffect } from "react";
import gsap from "gsap";
import RWorldWhite from "../assets/icons/RWorldWhite.png";
import RWorldBlack from "../assets/icons/RWorldBlack.png";
import ProfilePicture from "../assets/icons/Revens/ProfilePicture.png";
import RateXIcon from "../assets/icons/Ratex.png";
import { PiFloppyDiskBack } from "react-icons/pi";
import { CiAlarmOn } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { CiLaptop } from "react-icons/ci";
import { GrNotification } from "react-icons/gr";
import ReusableDropDown from "./DropDown/DropDown";
import NormalDropDown from "./DropDown/NormalDropDown";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import getToken from "../utils/getToken";
import { useSwitchToRatexQuery } from "../redux/slices/onBoarding";
import Calendar from "./calendar";
import { FaCalendarAlt } from "react-icons/fa";
import { TfiArrowTopRight } from "react-icons/tfi";
import { useGSAP } from "@gsap/react";

import sunpic from "../assets/navbar/darkLightToggle/sun.svg";
import sunbg from "../assets/navbar/darkLightToggle/sunBg.svg";
import moon from "../assets/navbar/darkLightToggle/moon.svg";
import moonbg from "../assets/navbar/darkLightToggle/moonBg.svg";
import ratexDark from "../assets/bgImage/rateXdarkBg.jpeg";
import ratexLight from "../assets/bgImage/rateXlightBg.png";

const Header = ({ setSideBarOpen, sideBarOpen }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const options = ["Option 1", "Option 2", "Option 3"];
  const [contentVisible, setContentVisible] = useState(true); // State for content visibility

  const [customDate, setCustomDate] = useState(new Date());

  const token = getToken();

  const handleNavigate = (hId) => {
    window.location.href = `http://localhost:3000/ratex/token:${token}/hId:${hId}`;
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );

  const body = document.body;
  useEffect(() => {
    if (theme === "dark") {
      document.querySelector(".themeToggle").classList.add("dark");

      body.style.backgroundImage = `url(${ratexLight})`;
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector(".themeToggle").classList.remove("dark");
      body.style.backgroundImage = `url(${ratexDark})`;
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };


  useGSAP(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setContentVisible(true);
      },
    });

    tl.from(".notch", {
      width: 0,
      duration: 1.8,
      ease: "power2.out",
    })
    
      .from(".opi", {
        opacity: 0,
        x:-100,
        duration: 1,
        stagger: 0.3,
        ease: "expo.inOut",
      }, ); // overlapping with previous animation
      tl.from(".innernotch", {
        width: 0,
        duration: 0.5,
        ease: "expo.in",
      },"-=150%")
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="w-[100%] h-[7vh] flex justify-between  px-3 py-1 items-center">
      <div className="flex w-fit justify-between gap-4">
        <div className="ml-7 flex gap-4">
          <div
            onClick={() => {
              setSideBarOpen((prev) => !prev);
            }}
            className="cursor-pointer"
          >
            {sideBarOpen ? (
              <IoCloseSharp size={20} />
            ) : (
              <GiHamburgerMenu size={20} />
            )}
          </div>
          <div>
            <img className="" width={70} src={RWorldBlack} alt="Logo" />
          </div>
        </div>
      </div>
      <div className="w-[40%] flex items-center px-4 justify-evenly h-[90%] text-white bg-black rounded-2xl notch">
        {contentVisible && (
          <div className="flex w-full items-center px-4 justify-evenly h-[90%] text-white bg-black rounded-2xl">
            <div className="flex items-center justify-center">

            <div className="overflow-hidden opi">
              <h1 className="text-sm">Your Schedule</h1>
            </div>
            <div className=" ">
              <div className="mx-4 relative flex items-center h-full opi">
                <Calendar
                  selectedDate={customDate}
                  setSelectedDate={setCustomDate}
                  enableDaysFromNow={60}
                  backgroundNone={true}
                  showIconBackground={true}
                  icon={<FaCalendarAlt size={12} />}
                  darkTheme={true}
                  height={"80"}
                  />
              </div>
                  </div>
            </div>
            <div className="overflow-hidden h-[70%] w-[60%] innernotch">
              <div className="w-full flex items-center justify-center text-black px-4 h-full  text-sm bg-[#9aff77] rounded-2xl content">
                <div className=" border-r px-2 border-black">2:00 pm</div>
                <div className="w-[50%] rounded-xl border border-black h-[80%] mx-2 bg-[#9aff77]/40 " >

                </div>
                <div className=" border-l px-2 border-black">3:00 pm</div>
              </div>
            </div>
            <div className="overflow-hidden opi">
              <div className="arrow">
                <TfiArrowTopRight />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="">
        <div className="flex border-l-2 px-3 border-white h-full justify-center items-center gap-5 cursor-pointer">

        <div
                onClick={toggleTheme}
                className={`relative w-16 h-8  rounded-full cursor-pointer overflow-hidden transition-all duration-300 ${
                  theme === "light" ? "bg-transparent" : "bg-transparent"
                }`}
                style={{
                  backgroundImage: `url(${theme === "light" ? moonbg : sunbg})`,
                  backgroundSize: "cover",
                }}
              >
                {/* Sun/Moon Image */}
                <div
                  className={`absolute w-6 h-6 flex justify-center items-center rounded-full top-[5px] transition-transform duration-300 ${
                    theme === "light"
                      ? "translate-x-[0.1rem]"
                      : "translate-x-[2.5rem]"
                  }`}
                  style={{
                    backgroundImage: `url(${theme === "light" ? moon : sunpic})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
          <div className="notification relative">
            <div className="z-[2]">
              <GrNotification />
            </div>
            <div className="absolute -top-2 bg-red-400 rounded-full px-1 z-0 text-[10px] -right-3">
              1
            </div>
          </div>
          <div className="h-7 w-7 bg-orange-400 rounded-full border-2 overflow-hidden">
            <img
              src={ProfilePicture}
              alt=""
              className="w-14 h-14 relative object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
