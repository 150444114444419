import React, { useState } from "react";
import ReusableInput from "../InputField/ReusableInput";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import Cookies from "js-cookie";
import { FaCalendarAlt } from "react-icons/fa";
import Calendar from "../calendar";
import CustomCheckBox from "../CheckBox/customCheckBox";
import NormalDropDown from "../DropDown/NormalDropDown";

const BuisnessInfo = ({
  onSubmit,
  selectedLanguage,
  setSelectedLanguage,
  dob,
  setDob,
  businessinfoError,
}) => {
  const methods = useForm();

  const [languageOptions, setLanguageOptions] = useState([
    "English",
    "Hindi",
    "Sanskrit",
    "Spanish",
  ]);

  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Let's Get Started</h1>
        <p className="text-[#96A0AD] my-2">
          To make your experience as smooth and effcient as possible. we'd love
          to know you a little better. Please enter your details below
        </p>
      </div>
      <div>
        <div>
          <p>Date of Birth</p>
          <div className="flex relative ">
            <Calendar
              selectedDate={dob}
              setSelectedDate={setDob}
              // backgroundNone={true}
              showIconBackground={true}
              onlyPastDaysSelectable={true}
              label={"Select Birthdate"}
              // onlyPastDaysSelectable={true}
              icon={<FaCalendarAlt size={24} />}
              darkTheme={false}
            />
          </div>
        </div>
        <div className="my-2">
          <p className="my-2 ">Gender</p>
          <div className="flex gap-2 items-center">
            <div>
              <input
                type="radio"
                id="male"
                name="gender"
                value="Male"
                {...register("gender", { required: "Gender is required" })}
              />
              <label htmlFor="male" className="ml-2">
                Male
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="female"
                name="gender"
                value="Female"
                {...register("gender", { required: "Gender is required" })}
              />
              <label htmlFor="female" className="ml-2">
                Female
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="other"
                name="gender"
                value="Others"
                {...register("gender", { required: "Gender is required" })}
              />
              <label htmlFor="other" className="ml-2">
                Other
              </label>
            </div>
          </div>
          {errors.gender && (
            <p className="text-red-500">{errors.gender.message}</p>
          )}
        </div>
        <div className="my-2">
          <p className="my-2 ">Language</p>
          <NormalDropDown
            options={languageOptions}
            label="Select an option"
            selectedValue={selectedLanguage}
            setSelectedValue={setSelectedLanguage}
            isMulti={true}
          />
        </div>

        <p className="text-red-500"> {businessinfoError}</p>
      </div>
    </div>
  );
};

export default BuisnessInfo;
