import React from 'react'
import '../Loader/UniversalLoader.css'
const TrueFalseModal = ({setShowModal,functionCall}) => {
    const handleOK = () =>{
        setShowModal(false)
        if (functionCall) { // Check if functionCall is defined
            functionCall(); // Call the function
        }
        // add your logic here to add item
        // for example: dispatch(addItem({name: 'new item'}))
  
    }
  return (
    <div className="backdrop w-[100vh] h-[100vw]">
      <div className="bg-white p-6 rounded shadow-md">
        <p className="mb-4">Are you sure you want to add this item?</p>
        <button onClick={handleOK} className="mr-2 bg-blue-500 text-white px-4 py-2 rounded">OK</button>
        <button onClick={()=>{setShowModal(false)}}className="bg-gray-300 text-black px-4 py-2 rounded">Cancel</button>
      </div>
      </div>

  )
}

export default TrueFalseModal