import React from 'react'

const FAQBox = ({title, imageUrl}) => {
  return (
    <div className='p-2 border-gray-400  border-2 w-56 text-sm h-32 rounded-md'>
<h1>{title}</h1>
<img src={imageUrl} alt="" />
    </div>
  )
}

export default FAQBox