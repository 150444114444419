import React from "react";

const UserProfileWrapper = ({ children,label }) => {
  return (
    <div className=" flex flex-col gap-2 p-4 bg-white rounded-lg w-full">
      <h1 className="border-b-2 text-slate-500">{label}</h1>
      {children}
    </div>
  );
};

export default UserProfileWrapper;
