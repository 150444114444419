import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getToken from "../utils/getToken";
import { BASE_URL } from "../helpers/helpers";

const localStorageToken = getToken();

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.token;
      if (token || localStorageToken) {
        headers.set("authorization", token || localStorageToken);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    "login",
    "signup",
    "onBoarding",
    "basicDetails",
    "OTPVerfication",
    "ResendOTP",
    "loginInfo",
    "forgotPassword",
    "resetPassword",
    "getAllCategory",
    "onBoardingInviteMember",
    "useDesignationQuery",
    "addScenarioByUser",
    "updateFrequency",
    "getScenarioFrequency",
    "revenueGrowth",
    "getActivityManager",
    "getChannelPerformance",
    "getPropertyChain",
    "getStarCategory",
    "getAllFaq",
    "getClientPerformance",
    "addClient",
    "addEmployee",
    "getOnboardingFunnel",
    "getClient",
    "getUsersList",
    "getClientDetail",
    "getUserDetail",
    "getDepartment",
    "switchToRatex",
    "getDesignation",
    "updateCurrentScreen"
  ],
});
