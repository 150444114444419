import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Button = ({
  children,
  onClick = () => {},
  isLoading = false,
  type = "primary",
  dropdownOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const baseClasses =
    "flex items-center  justify-center  text-[14px] font-semibold leading-none px-5 h-10  rounded-[9px] w-fit cursor-pointer shadow-custom";

  return (
    <button
      type="submit"
      className={`relative ${baseClasses} ${type == "primary" || type === "dropdown" ? "bg-RGreen text-white" : type === "light" ? "bg-RLight" : "bg-RDark text-white"}  `}
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        {" "}
        {isLoading ? "Loading.." : children} {type === "dropdown" && <IoIosArrowDown onClick={()=>setIsOpen(!isOpen)} />}
      </div>
      {isOpen && <div className="absolute flex flex-col gap-2 rounded-md bg-white overflow-hidden -bottom-[80px] z-10">
        {dropdownOption && dropdownOption?.map((item)=>{
          return <div className="p-2 text-sm text-black font-light bg-white hover:bg-gray-100 transition-all ease-in-out duration-150">{item}</div>
        })}
        </div>}
    </button>
  );
};

export default Button;
