import React from "react";
import { useNavigate } from "react-router-dom";

const RevenueManager = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/All-client");
  };
  function getGreeting() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      return "Good morning";
    } else if (hour >= 12 && hour < 17) {
      return "Good afternoon";
    } else if (hour >= 17 && hour < 20) {
      return "Good evening";
    } else {
      return "Good night";
    }
  }
  return (
    <div className="h-[93vh] flex items-center flex-col justify-center">
      <h2 className="text-lg font-medium">
        {new Date().toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })}
      </h2>
      <h1 className="font-semibold text-2xl">{getGreeting()}, Partner!</h1>
      <div className="h-[100%] flex items-center flex-col justify-center">
        <h2 className="text-5xl font-bold bg-gradient-to-r leading-relaxed	 from-blue-500 to-purple-500 text-transparent bg-clip-text">
          Coming Soon
        </h2>
        <p className="text-lg mt-4">To manage client right now. Click below!</p>
        <button
          onClick={handleButtonClick}
          className="mt-4 bg-blue-500 	 text-white py-2 px-4 rounded-lg"
        >
          Manage Clients
        </button>
      </div>
    </div>
  );
};

export default RevenueManager;
