import React from "react";
import FAQExpanded from "../../component/FAQ/FAQExpanded";
import { useGetAllFaqQuery } from "../../redux/slices/dashboard";

const FAQs = () => {
  const { data: faqData } = useGetAllFaqQuery();
  return (
    <div className="w-[100%] bg-rbg p-4 min-h-[100%]">
      <div className="w-[62%] ">
        <h1 className="text-2xl font-bold">Frequently asked question</h1>

        <p className="mt-5">
          Stuck on something partner? we're here to help with all the question
          and answers in one place
        </p>
      </div>
      <div className="w-[100%] flex-wrap flex justify-between mx-auto ">
        {faqData?.data?.map((faq) => (
          <div className="w-[48%] mt-4">
            <FAQExpanded heading={faq.question} content={faq?.answer} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
