import React, { Component } from "react";

const Test = () => {
  return (
    <>
      <div className="flex justify-center items-center h-[100%] w-[100%] flex-col">
        <div className="">
          welcome modal
          <div className="h-52 w-52 bg-newGradient dark:bg-lightGradient text-red-400 flex justify-center items-center">
            hi
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
