import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import ReusableInput from '../InputField/ReusableInput';
import MapComponent from '../Map/mapComponent';

const PersonalLocation = ({location ,setLocation ,personalLocationError, loginInfo }) => {
  const [log, setLog] = useState();
  const [lat, setLat] = useState();
  const [address, setAddress] = useState(location?.address ? location?.address : '');
  const [state, setState] = useState("");
  const [country, setCountry] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState("");
console.log(log,"log Initial value")
  useEffect(() => {
    if (loginInfo) {
      
      // Assuming loginInfo contains the necessary location data
      setLog(
        loginInfo?.data?.companyDetail?.address?.longitude 
          ? JSON.parse(loginInfo.data.companyDetail.address.longitude)
          : log
      );
      
      setLat(
        loginInfo?.data?.companyDetail?.address?.latitude 
          ? JSON.parse(loginInfo.data.companyDetail.address.latitude)
          : lat
      );
      setAddress(loginInfo.data?.companyDetail?.address?.fullAddress || address);
      setState(loginInfo.data?.companyDetail?.address?.state || state);
      setCountry(loginInfo.data?.companyDetail?.address?.country || country);
      setCity(loginInfo.data?.companyDetail?.address?.city || city);
      setPostalCode(loginInfo.data?.companyDetail?.address?.postalCode || postalCode);
    }
  }, [loginInfo]);

  useEffect(() => {
    if (lat || log || city || country || state || postalCode || address) {
      setLocation({ latitude: lat, longitude: log, city, country, state, cityCode: postalCode,fullAddress:address });
    }
    
  }, [lat, log, city, country, state, postalCode,address]);

  console.log(location, "location", address, "address");

  return (
    <div className="w-[100%] h-[100%]">
      <div>
        <h1 className="text-3xl font-semibold">Locate yourself on search</h1>
        <p className="text-[#96A0AD] my-2">
        Knowing your location allows us to offer additional benefits
        </p>
      </div>
      <div>
        <MapComponent
          version='weekly'
          libraries={['places']}
          log={log ||75.89214199999999}
          setLog={setLog}
          lat={lat ||22.7457114}
          setLat={setLat}
          address={address}
          setAddress={setAddress}
          state={state}
          setState={setState}
          country={country}
          setCountry={setCountry}
          city={city}
          setCity={setCity}
          postalCode={postalCode}
          setPostalCode={setPostalCode}
        />
      </div>
      {personalLocationError && <p className="text-red-500">{personalLocationError}</p>}
    </div>
  )
}

export default PersonalLocation