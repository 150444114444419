import React from "react";
import Bubbles from "../../assets/icons/Circles/Bubble.svg";
import SemiBubble from "../../assets/icons/Circles/semiBubble.svg";
import UpSideDown from "../../assets/icons/Circles/UpSideDown.svg";
import { useLocation } from "react-router-dom";
import ForgetPassword from "../../assets/icons/ForgotPassword.svg";
import ForgotPassword from "../../component/ForgotPassword/ForgotPassword";
import ResetPassword from "../../component/ChangePassword/ChangePassword";
import SelectableTabs from "../../component/SelectableTabls";
import Button from "../../component/button";
import RevensScreen from "../../component/OnBoarding/RevensScreen";
import RevensCalling from "../../assets/icons/Revens/smallRevensCalling.svg";
import Scenario from "./Scenario";
import { useIsIpad } from "../../utils/isIpad";

const LoginDashboard = () => {
  const location = useLocation();
  let token = null;
  const isForgotPage =
    location.pathname === "/forgot-password" ||
    location.pathname.includes("/change-password/");
  if (location.pathname.includes("/change-password/")) {
    const pathParts = location.pathname.split("/change-password/");
    if (pathParts.length > 1) {
      token = pathParts[1];
    } else {
      console.error("Token not found in the URL");
    }
  }
  const isIpad = useIsIpad();

  return (
    <div className=" relative w-[100vw] h-[100vh] flex flex-col">
      <div className="w-[100%] bg-RGreen flex items-center justify-center h-[50%]">
        <div className={`w-[100%] h-[100%] ${isForgotPage && "relative "}`}>
          {location.pathname === "/loginDashboard" && (
            <div>
              <div className="absolute left-0 top-0">
                <img src={Bubbles} className="w-[100%] h-[100%]" />
              </div>
              <div className="absolute right-0 bottom-0">
                <img src={SemiBubble} className="w-[100%] h-[100%]" />
              </div>
              <div className="absolute top-0 left-[50%]">
                <img src={UpSideDown} className="w-[100%] h-[100%]" />
              </div>
              <div className="flex mx-10 py-10 md:gap-6 flex-col h-[100%] w-[100%] md:w-1/3">
                <div className="flex flex-col text-white"></div>
              </div>
            </div>
          )}
          {isForgotPage && (
            <div className="absolute left-28 -bottom-56 flex items-center w-[600px] justify-center">
              <div className="bg-white relative z-10 p-10 rounded-lg shadow-lg w-full">
                {location.pathname === "/forgot-password" && <ForgotPassword />}
                {location.pathname.includes("/change-password/") && (
                  <ResetPassword />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {isForgotPage && (
        <div
          className={`absolute ${isIpad ? " w-[400px] " : " "}  bottom-0 right-0`}
        >
          <img src={ForgetPassword} className="w-[100%] h-[100%]" />
        </div>
      )}
    </div>
  );
};

export default LoginDashboard;
