import React, { useState } from "react";
import FAQExpanded from "../../component/FAQ/FAQExpanded";
import {
  useGetAllFaqQuery,
  useGetChannelPerformanceQuery,
} from "../../redux/slices/dashboard";
import UniversalTable from "../../component/Table/UniversalTable";
import Tablewrap from "../../component/Table/TableWrap";
import Button from "../../component/button";
import AddClient from "./AddClient";
import { useGetClientQuery } from "../../redux/slices/Setting";
import SideBar from "../../component/SideBar";
import Header from "../../component/Header";
import UniversalLoader from "../../component/Loader/UniversalLoader";

const AllClient = () => {
  const [showAddClient, setShowAddClient] = useState(false);
  const {
    data: clientData,
    isLoading,
    isFetching,
    refetch: getClientRefetch,
  } = useGetClientQuery();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  return (
    <>
      <UniversalLoader loading={isLoading || isFetching} />

      <div className="flex w-full">
        {showAddClient ? (
          <AddClient
            getClientRefetch={getClientRefetch}
            setShowAddClient={setShowAddClient}
          />
        ) : (
          <div className="w-full h-[93vh] overflow-y-auto   bg-rbg p-4 flex flex-col justify-start items-start ">
            <div className="w-[100%] flex justify-between  p-4 ">
              <div className="flex">
                <div>
                  <h1 className="text-2xl font-bold">All Clients</h1>

                  <p className="">
                    Let's keep a check on your clients partner!
                  </p>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setShowAddClient((prev) => !prev);
                  }}
                >
                  Add Client
                </Button>
              </div>
            </div>
            <div className="w-[100%] mt-6 flex-wrap flex justify-between mx-auto ">
              <Tablewrap label={"Hotel Onboarded"}>
                <UniversalTable
                  isLoading={isLoading}
                  dataArray={clientData?.data}
                  showActions={"client"}
                />
              </Tablewrap>
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default AllClient;
